@import 'material-icons/iconfont/material-icons.scss';
@import 'abstracts/variable';
@import 'abstracts/mixins';

$primary-color: #2C74FE;
$secondary-color: #FFB049;
$green-color: #05CA7B;
$red-color: #EA5B62;
// a:hover {
//     color: #4e4b4b !important;
// }



.card-one {
    background: linear-gradient(#FECA45, #FF8956);
    min-height: 100%;
    background-size: cover;
}

.card-two {
    background: linear-gradient(#3AD89A, #38B3B4);
    min-height: 100%;
    background-size: cover;
}

.card-three {
    background: linear-gradient(#2CC6DE, #5881E8);
    min-height: 100%;
    background-size: cover;
}

.card-four {
    background: linear-gradient(#FE7278, #F75795);
    min-height: 100%;
    background-size: cover;
}

html,
body {
    height: 100%;
    background: #FAFAFA;
}

body {
    font-family: "Lato", sans-serif;
    padding: 0;
    margin: 0;
}

.header {
    background-color: #fff;
    // border-bottom: solid 1px #E4D6D5;
    font-family: "Lato", sans-serif;

    .top-header {
        padding: 0.5rem 1.2rem;
    }

    .bg-nav {
        background-color: #0f75bc;
        // border-top: solid 2px #FF8956;
        border-top: solid 2px #dddddd;
        border-bottom: solid 2px #dddddd;

        a {
            color: #fff;
            font-size: 0.8rem;
        }
    }


    .navigation {
        // padding: 0.15rem 0.8rem;

        .header-menu {
            padding: 0 1.8rem;

            .mat-button,
            .mat-icon-button,
            .mat-stroked-button,
            .mat-flat-button {
                padding: 0;
                min-width: auto;
            }

            .mat-icon {
                font-size: 16px;
                height: 14px;
                width: 12px;
                margin-top: -5px;
                margin-right: 4px;
            }

        }

        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            width: auto;

        }

        .navbar-nav {
            li {
                padding-right: 0.65rem;
                padding-left: 0.65rem;

                img {
                    display: inline-block;
                    margin-top: -3px;
                    margin-right: 3px;
                }

                .dropdown-toggle::after {
                    margin-top: 2px;
                    vertical-align: middle;
                }

                &:last-child {
                    padding-right: 0;
                }
            }

            .dropdown-menu {
                margin-top: 0.35rem;

                li .dropdown-item {
                    color: #000000;
                    font-size: .9rem;
                    padding: .5rem;
                    transition: .35s;
                }

                li {

                    .dropdown-item:hover,
                    .dropdown-item:focus {
                        background-color: #0f75bc;
                        color: #fff;
                        border-radius: 0.125rem;
                        -webkit-box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
                        box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
                        -webkit-transition: all .1s linear;
                        transition: all .1s linear;
                    }
                }
            }
        }

        li:first-child {
            padding-left: 0;
        }

        .user-profile {
            padding: 0;

            li {
                padding-right: 0.6rem;
                padding-left: 0.6rem;

                .dropdown-toggle::after {
                    margin-top: 2px;
                    vertical-align: middle;
                }
            }

            .profile-pic {
                background-color: #DCDCDC;
                border-radius: 50%;
                border: solid 2px #fff;
                padding: 0.25rem 0.32rem;
                margin-right: 0;

                img {
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: -5px;
                }
            }

            .dropdown-menu {
                margin-top: 0.35rem;

                li .dropdown-item {
                    color: #000000;
                    font-size: .9rem;
                    padding: .5rem;
                    transition: .35s;
                }

                li {

                    .dropdown-item:hover,
                    .dropdown-item:focus {
                        background-color: #0f75bc;
                        color: #fff;
                        border-radius: 0.125rem;
                        -webkit-box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
                        box-shadow: 0 5px 11px 0 rgb(0 0 0 / 18%), 0 4px 15px 0 rgb(0 0 0 / 15%);
                        -webkit-transition: all .1s linear;
                        transition: all .1s linear;
                    }
                }
            }
        }

        .mat-button {
            font-family: "Lato", sans-serif;
            font-size: .85rem;
            cursor: pointer;

            .mat-button-focus-overlay {
                background: #000;
            }

            .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
            .mat-stroked-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay {
                opacity: .15;
                border-radius: 0;
            }
        }
    }
}

ul li:hover {
    // z-index: 999;
    // color: #0a0a0a;
}

.card-section,
.data-table,
section {
    padding: 1rem;
    // padding-bottom: 2rem;
}

section,
main {
    .title-one {
        color: #444444;
        font-size: 18px;
        font-family: 'Lato', sans-serif;
        font-weight: 500;
        margin-bottom: 0.7rem;
    }
}

#main {
    .panel-title {
        color: #464646;
        font-size: 18px;
        font-family: 'Lato', sans-serif;
        font-weight: 500;
        margin-bottom: 0.75rem;
    }
}

.card-section {
    .custom-select {
        display: inline-block;
        position: relative;
        top: 0;
        //-webkit-transform: translateY(-50%);
        // -ms-transform: translateY(-50%);
        //transform: translateY(-50%);
        border-radius: 5px;
        box-shadow: 0 0 1em rgba(white, 0.2), inset 0 0 1px rgba(white, 0.8);
        /* Styling the select background */
        background-color: lighten(#1FBDED, 20%);
        cursor: pointer;
        margin-top: 0;

        select {
            width: auto;
            margin: 0;
            padding: .75em 1.5em;
            outline: none;
            cursor: pointer;
            border: none;
            border-radius: 0;
            background-color: transparent;
            /* Styling the select text color */
            color: darken(#1FBDED, 35%);

            /* removes the fucking native down arrow */
            -webkit-appearance: none;
            -moz-appearance: none;
            text-indent: 0.01px;
            text-overflow: '';

            &::-ms-expand {
                display: none;
            }

        }

        &:after {
            position: absolute;
            top: 1em;
            right: 1em;
            /* Styling the down arrow */
            width: 0;
            height: 0;
            padding: 0;
            content: '';
            border-left: .25em solid transparent;
            border-right: .25em solid transparent;
            border-top: .375em solid darken(#fff, 10%);
            pointer-events: none;
        }
    }

    small,
    .small {
        font-size: 0.75rem;
    }

    .card {
        //background: url("assets/images/icons/path_44.svg");
        //padding-top: 0.75rem;
        //padding-bottom: 0.75rem;
        background-size: cover;
        border: solid 1px #fff;
        border-radius: 0.25rem;
        box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
        min-height: 172px;

        &:before {
            content: "";
            background: url("../assets/images/icons/path_44.svg") center/cover no-repeat;
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: .12;
            background-size: contain;
        }

        .progress {
            padding: 0.02rem;
            border-radius: 6rem;
            border: solid 1px #f2f2f2;
            height: 0.6rem;

            //margin-top: 0.75rem;
            .progress-bar {
                border-radius: 6rem 0 0 6rem;
            }
        }

        .card-title {
            font-size: 1rem;
            color: #ffffff;
            line-height: 1rem;
            margin-bottom: 0;
            font-family: "Lato", sans-serif;
            margin-top: 0;
            vertical-align: middle;
            display: flex;
            text-decoration: none;

            &:hover {
                text-decoration: underline;
            }
        }

        .card-count {
            font-size: 1.6rem;
            color: #ffffff;
            text-align: left;
            font-weight: 500;
            margin-bottom: 0;
            font-family: 'Lato', sans-serif;
        }

        .list-item {
            li {
                background-color: #fff;
                margin-bottom: 0.25rem;
                border-radius: 6rem;

                a {
                    color: #000000;
                    font-size: 0.65rem;
                    font-weight: 500;
                    text-decoration: none;

                    &:hover {
                        color: #0f75bc !important;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        .flex-shrink-0 {
            text-align: left;
            margin-left: 6px;
        }

        [class*="flex"] {
            img {
                margin-right: 0.5rem;
            }
        }

        .filter-card {

            .mat-form-field-infix {
                padding: 0;
                width: 100px !important;
            }

        }

        .card-body {
            position: relative;
            padding: 1rem;
        }
    }


}

.card-filter {
    .form-select {
        height: 24px;
        background: #333;
        color: #fff;
        line-height: normal;
        font-size: .5rem;
        cursor: pointer;
    }

}

.dd-select {
    background-color: #000000;

    mat-label {
        color: #fff;

    }

    mat-select {
        line-height: normal;
    }
}

.data-table {
    margin-bottom: 10rem;

    .card {
        box-shadow: rgb(17 12 46 / 12%) 0px 2px 20px 0px;
        border: none;
    }

    .card-body {
        padding: 2rem;
    }

    table {
        width: 100%;

        thead tr,
        tbody tr {
            line-height: 20px !important;
            border: solid 1px #EBEBEB;
        }

        tbody tr {
            line-height: normal !important;

            td {
                padding: 0.5rem 0.5rem;
                vertical-align: middle;
            }
        }

        th[scope="row"] {
            background-color: #ffffff;
        }

        th,
        td {
            text-align: center;
            color: #000000;
            font-size: 0.75rem;
        }

        tbody tr:nth-child(odd) {
            background-color: #FFFFFF;
        }

        tbody tr:nth-child(even) {
            background-color: #f5f5f5;
        }

        thead tr {
            background-color: #ffffff;
            font-weight: 500;
            border-bottom: solid 3px #f5f5f5;

            th {
                font-weight: 500;
                background-color: #ffffff;
            }
        }

        tbody tr td {
            background-color: #fff;
        }
    }

    .page-item.active .page-link {
        z-index: inherit;
    }




}
.data-filter {
    .form-control {
        border: 1px solid #444444;
    }

    select,
    input {
        color: #000000;
        font-size: .75rem;
        padding: 0.25rem 0.75rem;
    }

    .input-append {
        position: relative;

        input {
            padding-left: 36px;
        }

        .icon-in {
            position: absolute;
            left: 0;
            top: 0;
            // z-index: 999;
        }
        ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #212529!important;
        }
        :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #212529!important;
          }
        ::-ms-input-placeholder { /* Microsoft Edge */
            color: #212529!important;
          }
    }
  label {
    // min-width: 15%;
  }
select {
    background: url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
    background-position: calc(100% - 0.75rem) center !important;
    -moz-appearance:none !important;
    -webkit-appearance: none !important;
    appearance: none !important;
    // padding-right: 2rem !important;
}

}
#input-filter {

    // Added css from internal pages
    .form-control {
        display: inline-block;
    }

    select {
        width: 14%;
        margin-right: 1%;
    }

    .date {
        width: 14%;
        margin-right: 1%;
    }

    label {
        margin-right: 1%;
        font-weight: 400;
    }

    .second-col {
        margin-top: 0.5% !important;
    }

    .third-col {
        margin-top: 0.5% !important;
    }

    // end css of internal pages
}

.card-filter {

    .mat-select-arrow {
        color: rgb(255 255 255);
    }

    .mat-form-field-label {
        color: rgb(255 255 255);
    }

    .custom-select {
        background-color: rgba(25, 28, 36, 0.25);
        color: #fff;
        font-size: 0.6rem;
        margin-top: .75rem;
        border-radius: .25rem;
        border: solid 1px #fff;

        select {
            option {
                color: #000000;
            }

            color: #fff;
            height: 1rem;
            line-height: 0.75rem;
            padding: 0 1.2rem 0 0.5rem;

            &:after {
                position: absolute;
                top: 0.25em;
            }
        }
    }
}





@-moz-document url-prefix() {
    .custom-select select {
        padding-right: 1.75em;
    }
}

#chart {
    max-width: 380px;
    margin: 35px auto;
    padding: 0;
}

.pie {
    width: 200px;
    height: 200px;
    position: relative;

}

.slice {
    clip-path: polygon(50% 0%, 50% 100%, 100% 100%, 100% 0%);
    position: absolute;
    border-radius: 50%;
    width: 100%;
    height: 100%;
}

.slice-fill {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #0099FF;
    clip-path: polygon(50% 0%, 50% 100%, 0% 100%, 0% 0%);
    position: absolute;
    z-index: 2;
}

.bg-fill {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background: #0099FF;
    position: absolute;
    display: none;
    z-index: 1;
    clip-path: polygon(50% 0%, 50% 100%, 0% 100%, 0% 0%);
    transform: rotate(180deg);
}

//   .legend {
//     position: absolute;
//     margin-left: 20px;
//     left: 120%;
//     width: 200px;
//     list-style: none;
//     padding: 0;
//   }
.legend li {
    margin: 10px;
    color: black;
}

.legend li:before {
    width: 10px;
    height: 10px;
    content: "";
    display: inline-block;
    margin-right: 5px;
}

.mat-menu-panel {
    transform-origin: left top;
    margin-top: 3px;
}

@for $i from 1 through 100 {
    .slice[data-value="#{$i}"] .slice-fill {
        transform: rotate(#{$i * 3.6}deg);
    }

    .slice[data-start="#{$i}"] {
        transform: rotate(#{$i * 3.6}deg);
    }

    @if ($i > 50) {
        .slice[data-value="#{$i}"] {
            clip-path: none;
        }

        .slice[data-value="#{$i}"] .bg-fill {
            display: block;
        }
    }
}


$colors: #274F7D, #B95632, #E0882B, #D4AF50, #B8AC34, #073450, #07899E, #50D5B3, #F3D671, #E73936;

@for $i from 1 through 10 {

    .slice:nth-of-type(#{$i}) .slice-fill,
    .slice:nth-of-type(#{$i}) .bg-fill,
    .legend li:nth-child(#{$i}):before {
        background-color: nth($colors, $i);
    }
}

@media screen and (-webkit-min-device-pixel-ratio:0) {
    .custom-select select {
        padding-right: 2em;
    }
}



.card {
    box-shadow: rgb(17 12 46 / 12%) 0px 2px 20px 0px;
    border: none;

    .card-body {
        padding: 1rem;
    }
}



.custom-table {
    font-family: "Roboto", sans-serif;
    font-size: .75rem;

    table {
        width: 100%;

        thead {
            border-bottom: 1px solid #dddddd;
        }

        tbody {
            border: none !important;
        }

        tbody tr {
            line-height: 20px;

            td {
                padding: 0.15rem 0.5rem;
            }
        }

        th[scope="row"] {
            background-color: #ffffff;
        }

        th,
        td {
            text-align: left;
            color: #000000;
            font-size: 0.75rem;
            border: none;
            padding: 0.5rem 0.5rem;
            border: solid 1px #DDDDDD;
        }

        thead tr {
            // background-color: #f5f5f5;
            font-weight: 500;
            // border-bottom: solid 3px #EFF2F4;
            line-height: 20px;

            th {
                font-weight: 500;
                width: auto;
                // background-color: #0f75bc;
                // color: #fff;
            }
        }

        tbody tr td {
            background-color: #fff;
            font-size: .75rem;
            vertical-align: middle;
        }
    }

    // button {
    //     font-size: 0.8rem;
    // }
    .mat-tab-body-content {
        overflow: hidden;
    }

    .sr-no {
        width: 80px;
    }
}

.data-filter {
    .form-control {
        border: 1px solid #444444;
    }

    .input-append {
        position: relative;
        display: inline-block;
        // width: 26%;
        margin-right: 10px;

        input {
            // padding-left:36px;
        }

        .icon-in {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 999;
            padding: 6px 10px 2px;
            font-size: 1.2rem;

            // right: 0px;
            // background-color: rgba(13, 110, 253, 0.9);
            // color: #ffffff;
            // font-size: 28px;
            // border-radius: 0 4px 4px 0;
        }
    }

    .form-select,
    input {
        font-size: 0.75rem;
        padding: 0.25rem 0.75rem;
    }

    .input-append {
        input {
            padding-left: 30px;
            padding-top: 4px;
            padding-bottom: 4px;
            // padding: 0.25rem 0.5rem;
        }
    }
}

.action-button {
    .mat-stroked-button {
        display: inline;
        min-width: auto;
        padding: 0.25rem 0.5rem;
        font-size: 0.75rem;
        line-height: 1;
        border: none;
    }

    .material-icons-sharp {
        font-size: 1.3rem;
    }
}

.custom-modal {
    font: 400 15px/24px "Lato", sans-serif;

    .modal-title {
        font-size: 1rem;
        font-weight: 500;
    }

    .mat-button {
        min-width: 24px;
        padding: 0;

        span {
            width: 41px;
            padding: 0;
            font-size: 1.4rem;
        }
    }

    .modal-button {
        // padding: .5rem 2rem;
    }

    input,
    select,
    label {
        font-size: .75rem;
    }

    .invalid-feedback,
    .text-danger {
        margin-top: 0.25rem;
        font-size: .65rem;
        line-height: normal;
    }

    .mat-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay,
    .mat-stroked-button:hover:not(.mat-button-disabled) .mat-button-focus-overlay {
        opacity: 0;
    }

    .mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle,
    .mat-radio-outer-circle {
        border-color: rgba(13, 110, 253, 0.9) !important;
    }

    .mat-radio-button.mat-accent .mat-radio-inner-circle {
        background-color: rgba(13, 110, 253, 0.9) !important;
    }

    sub,
    sup {
        font-size: 0.85rem;
        color: red;
        top: 0;
    }

    .modal-header {
        padding: 0.75rem;
    }

    .modal-footer {
        padding: 0.75rem;

        .btn {
            padding: 0.25rem 0.75rem;
            font-size: .85rem;
        }
    }

}

.modal {
    .modal-dialog {
        display: flex;
        align-items: center;
        min-height: calc(100% - 1rem);
        margin: auto;
    }
}

.ut-btn {
    font-size: .85rem;
    padding: 0.375rem 0.75rem;
}

.add-btn {
    margin-top: -2px;
}

.go-btn {
    padding: 0.25rem 0.35rem;
    line-height: 1;
    align-self: center;
    display: inline-block;

    // font-size: .1rem;
    span,
    svg {
        font-size: 1.1rem;
        vertical-align: middle;
    }
}

.btn {
    cursor: pointer;
    // font-size: .75rem;
}

footer {
    bottom: 0;

    .copyright {
        p {
            font-size: 0.65rem;
        }
    }
}

.vt-inner-card {
    .card {
        border: solid 1px #dddddd;
        background-color: #F7FDFD;

        &:before {
            content: " ";
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            opacity: 1;
            margin: 0 auto;
            top: -24px;
            border: 12px solid transparent;
            border-bottom-color: #dddddd;

        }

        &:after {
            content: " ";
            position: absolute;
            left: 50%;
            transform: translate(-50%);
            opacity: 1;
            margin: 0 auto;
            top: -20px;
            border: 10px solid transparent;
            border-bottom-color: #F7FDFD;
            // border-top: 0.3em solid;
            // border-right: 0.3em solid transparent;
            // border-bottom: 0;
            // border-left: 0.3em solid transparent;
        }

        .card-body {
            padding: .75rem;

            h4 {
                font-size: .85rem;
                font-weight: 500;
                margin-bottom: -4px;
            }

            span {
                font-size: .65rem;
                vertical-align: middle;
                color: #888888;
            }
        }
    }

    .top-card-info {
        .card {
            &:before {
                content: " ";
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                opacity: 1;
                margin: 0 auto;
                top: inherit;
                bottom: -24px;
                border: 12px solid transparent;
                border-top-color: #dddddd;


            }

            &:after {
                content: " ";
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                opacity: 1;
                margin: 0 auto;
                top: inherit;
                bottom: -20px;
                border: 10px solid transparent;
                border-top-color: #F7FDFD;
            }
        }

    }


}

.stepbar {
    .nav-item {
        max-width: 142px;
    }
}


.custom-accordion {
    position: relative;

    // &:before{
    //     content: " ";
    //     position: absolute;
    //     transform: translate(-50%);
    //     opacity: .5;
    //     top: 24px;
    //     border: 1px solid #797979;
    //     left: -24px;
    //     min-height: 91%;
    // }
    .mat-expansion-panel {
        box-shadow: none;
        border: solid 1px #ddd;
        background: #F7FDFD;
        color: rgba(0, 0, 0, .87);
        overflow: inherit;

        // .mat-expansion-panel-header-title{
        //     display: flex;
        //      flex-grow: 0 !important;
        //     margin-right: 200px;
        //     padding-right: 0px;
        //     width: 200px;
        //     align-items: center;
        //   }


        .mat-expansion-panel-header-title,
        .mat-expansion-panel-header-description {
            color: #464646;
            width: 140px;
            align-items: center;
            font-weight: 500;
        }

        .mat-expansion-panel-body {
            background-color: #ffffff;
        }

        .mat-content {
            font-size: .85rem;
            align-items: center;
            overflow: inherit;
        }

        .mat-expansion-panel-header,
        .mat-expansion-panel {
            overflow: inherit;
            background-color: #F7FDFD;
        }

    }

    .material-icons {
        font-size: 2rem;
    }
}

.vertical-line {
    position: relative;

    &:before {
        content: " ";
        position: absolute;
        transform: translate(-50%);
        opacity: .5;
        top: 24px;
        border: 1px solid #797979;
        right: -30px;
        min-height: 91%;
    }
}

.btn-status {

    span {
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .mat-button-base {
        padding: 0.4rem 0.5rem;
        width: auto;
        line-height: normal;
        font-size: .65rem;
        min-width: 100px;
        text-align: center;
    }

    .material-icons {
        font-size: 16px;
        height: 16px;
        width: 16px;
    }

    .mat-success {
        background-color: #04CF68;
        border: solid 1px #04CF68;
        color: #ffffff;
    }

    .mat-pending {
        background-color: #FECA45;
        border: solid 1px #ffffff;
        color: #ffffff;
    }

    .mat-progress {
        background-color: #2C74FE;
        border: solid 1px #ffffff;
        color: #ffffff;
    }
}

.vt_status {
    margin-right: -101px;
    // right: -77px;
    position: relative;

    .mat-button-base {
        padding: 0.4rem 0.5rem;
        width: auto;
        line-height: normal;
        font-size: 2rem;
        min-width: 100px;
        text-align: center;
    }

    .material-icons {
        font-size: 24px;
        height: 24px;
        width: 24px;
        vertical-align: middle;
        // color: #04CF68;
        background-color: #ffffff;
        border-radius: 50%;
    }

    .mat-completed {
        color: #01D183;
    }

    .mat-open {
        color: #F88DA7;
    }

    .mat-pending {
        color: #FDB900;
    }

    .mat-running {
        color: #0893E4;
    }

    .mat-failed {
        color: #ff5733;
    }
}

.vehicleTransaction {
    .ticket-widget {
        height: 320px !important;
        overflow-y: auto;

        .ticket-item {
            padding: 2px 0;
            position: relative;
            border-bottom: 1px solid #f1f1f1;
            overflow: hidden;

            p {
                margin-bottom: 0;
                font-size: .65rem;
            }

            strong {
                width: 110px;
                display: inline-block;
            }

            .ticket-item-author {
                color: #333;
                font-weight: 600;
                display: block;
                margin-bottom: 6px;
            }

            .ticket-item-text {
                color: #a0a0a0;
                display: block;
                margin: 0;
                font-size: 12px;
            }

            .ticket-item-status {
                position: absolute;
                top: 2px;
                right: 10px;
                color: #a9a9a9;
                font-size: 10px;
            }
        }

        a {
            display: block;
            overflow: hidden;
            color: #464646;
        }
    }
}

.remarks {
    .media {
        border-bottom: solid 1px #ddd;
        margin-bottom: 10px;

        .media-body {
            font-size: .85rem;
            margin-bottom: 10px;
        }

        mat-icon {
            background: #e6e6e6;
            width: 40px;
            height: 40px;
            align-self: center;
            text-align: center;
            vertical-align: middle;
            line-height: 1;
            border-radius: 4px;
            margin-right: 10px;
            color: #aaa;
        }

        p {
            font-size: .65rem;
            margin-bottom: 0;
        }
    }
}

.select-radio {
    position: relative;
    display: flex;

    input {
        margin-right: 2px;
        margin-top: 0;
        cursor: pointer;
    }

    label {
        margin-right: 20px;
        cursor: pointer;
    }

    // &-input {
    //   visibility: hidden;
    // }

    // &-label {
    //   position: relative;
    //   padding-left: 35px;
    //   &:after {
    //     content: "";
    //     display: block;
    //     width: 12px;
    //     height: 12px;
    //     position: absolute;
    //     left: 4px;
    //     top: 4px;
    //     border-radius: 50%;
    //   }
    // }

    // &-border {
    //   width: 20px;
    //   height: 20px;
    //   display: inline-block;
    //   outline: solid 3px #d449e3;
    //   border-radius: 50%;
    //   position: absolute;
    //   left: 0px;
    //   top: 0px;
    // }
    // &-input:checked + &-label:after {
    //   transition: all 0.5s;
    //   background-color: #64236b;
    // }
}

.running-status {
    position: absolute;
    right: -2.7rem;
    top: 12rem;
}


.sm-table {
    .table>thead {
        vertical-align: bottom;
        // background-color: #DADADA;
        // color: #fff;
    }

    .table>tbody {
        border-top: 2px solid #f4f5f6;
    }

    th,
    td {
        font-size: .65rem;
        line-height: normal;
        // padding: 0.25rem;
        vertical-align: middle;
        padding: 0.25rem 0.25rem;
    }
}



.ngx-pagination {
    margin-bottom: 0!important;
    li {
        font-size: .75rem;
    }
}






.modal-content {
    margin: 0 !important;
    width: 100% !important;
    position: relative !important;
    margin-top: 0 !important;
    margin-right: 0 !important;
}

/* ===== Custom Scrollbar CSS ===== */

/* Firefox */
* {
    scrollbar-width: auto;
    scrollbar-color: #707070 #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 12px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: #b9b9b9;
    border-radius: 10px;
    border: 3px solid #ffffff;
    opacity: 0;
}

.vrScrollbar {
    height: 280px;
    padding-right: 0;
    overflow-y: scroll;
    mask-image: linear-gradient(to top, transparent, black),
        linear-gradient(to left, transparent 17px, black 17px);
    mask-size: 100% 20000px;
    mask-position: left bottom;
    -webkit-mask-image: linear-gradient(to top, transparent, black),
        linear-gradient(to left, transparent 17px, black 17px);
    -webkit-mask-size: 100% 20000px;
    -webkit-mask-position: left bottom;
    transition: mask-position 0.3s, -webkit-mask-position 0.3s;
}

.vrScrollbar:hover {
    -webkit-mask-position: left top;
    mask-position: left top;
}

.card-section {
    .row {
        margin-right: calc(-.25 * var(--bs-gutter-x));
        margin-left: calc(-.25 * var(--bs-gutter-x));
    }

    .row>* {
        padding-right: calc(var(--bs-gutter-x) * .25);
        padding-left: calc(var(--bs-gutter-x) * .25);
    }
}

.action-key {
    width: 8%!important;
}


.customize-mattab {
    .mat-tab-label {
        height: 36px;
        padding: 0 10px;
        // max-width: auto;
        // margin-left: 1rem;
        // margin-right: 1rem;
        // left: 2rem;


        // height: 36px;
        // padding: 0 10px;
        // min-width: auto;
        // left: 10px;
        // margin-left: 1rem;
        // margin-right: 1rem;
        // margin: auto;

        // &:last-child {
        //     margin-right: 0;
        // }
    }

    .mat-tab-label-active {
        color: #3f51b5;
    }

    // .mat-tab-group.mat-primary .mat-ink-bar,
    // .mat-tab-nav-bar.mat-primary .mat-ink-bar {
    //     background-color: #3f51b5;
    //     left: auto;
    //     right: auto;
    // }
    .mat-tab-header-pagination-controls-enabled .mat-tab-header-pagination { display: none;}
}

@media only screen and (max-width: 600px) {
    .main-logo {
        width: 200px;
    }

    .container {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .login {
        .card {
            width: 100%;
        }
    }

    .card-section {
        padding: 0;

        [class*="col"] {
            margin-bottom: 10px;
        }
    }

    .data-table {
        .data-filter {
            display: inline-block !important;
        }

        .flex-grow-1 {
            flex-grow: initial !important;
            display: block;
            width: 100%;
        }

        .flex-grow-2,
        .flex-shrink-0 {
            width: 47%;
            float: left;
            margin-top: 10px;
        }
    }



    #input-filter {
        // margin-top: 6px;
        .input-append {
            display: inline-block;
            margin-right: 0;
            width: 56%;
            margin-bottom: 6px;
            float: left;
        }

        select {
            width: 44%;
            display: flex !important;
            margin-bottom: 6px
        }

        .date {
            width: 28%;
        }
    }

    .card-section,
    .data-table,
    section {
        padding: 0;
        // padding-bottom: 2rem;
    }
    .customize-mattab {
        h4 { display: none;}
    }
    .xcvvxc { display: none;}

    .customize-mattab {
        .text-end {
            button {
                position: absolute;
                right: 0;
                margin-top: 8px;
                top: 0;
            }
        }
    }
    .vehicleTransaction {
        padding: 0;
    }
    .title-one {
        margin-bottom: 10px!important;
    }
    .input-append { width: 90%;}
    .mx-100 {
        .input-append { width: 100%;}
    }
    .data-filter {
        display: block!important;
    }
    .custom-accordion {
        form, .row, .card{
            display: block!important;
        }
    }
    .m_d-block {
        display: block!important;
    }
    .m_d-flex {
        display: flex!important;
    }
    #vehicle-parking {
        #input-filter {
            .input-append {
                margin-right: 0;
                width: 100%;
            }
            // select:first-child{display: block;}
            select {

                display: inline-block;
                width: 28%;
                float: left;
            }
            button {display: block;}
        }
        .select_top {
            display: block;
            width: 100%;
            margin: 0;
            clear: both;
            text-align: left;
            padding: 4px 10px;
        }
    }
    .vehicleTransaction  {
        .mat-expansion-panel-header-description, .mat-expansion-indicator::after {
            color: rgb(255 255 255 / 94%);
        }
    }

}
@media only screen and (min-width: 1200px) and (max-width: 1400px)  {
    .custom-table table th {
        font-size: 0.75rem;
    }
    .custom-table table td {
        font-size: 0.65rem;
    }

}


@media only screen and (min-width : 1400px) {
    .card-section {
        .card {
            min-height: 220px;
            padding: 2rem 1rem;
        }
    }


    // .custom-table {
    //     table {
    //         width: 100%;

    //         th,
    //         td {
    //             font-size: 0.8rem;
    //         }
    //     }


    // }
    .data-table table th, .data-table table td {
        font-size: 0.85rem;
    }
    .custom-table table tbody tr td {
        font-size: 0.85rem;
    }

    .header .navigation .mat-button {
        font-family: "Lato", sans-serif;
        font-size: 0.95rem;
    }
}

.tbl-formating {
    th {
        width: auto!important;
    }
    // th:nth-child(1){ width: 4%;}
    // th:nth-child(2){ width: 6%;}
    // th:nth-child(3){ width: 5%;}
    // th:nth-child(4){ width: 5%;}
    // th:nth-child(5){ width: 5%;}
    // th:nth-child(6){ width: 5%;}
    // th:nth-child(7){ width: 5%;}
    // th:nth-child(8){ width: 5%;}
    // th:nth-child(9){ width: 5%;}
    // th:nth-child(10){ width: 5%;}
    // th:nth-child(11){ width: 5%;}
    // th:nth-child(12){ width: 5%;}
  }



#queues {
    #pr_id_1 .p-carousel-item {
        flex: 0 0 auto!important;
        width: 16.66666667%;
    }
}
