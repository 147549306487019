


// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $Kemar.GAS.UI-primary: mat-palette($mat-indigo);
// $Kemar.GAS.UI-accent: mat-palette($mat-pink, A200, A100, A400);

// The warn palette is optional (defaults to red).
// $Kemar.GAS.UI-warn: mat-palette($mat-red);

// // Create the theme object. A theme consists of configurations for individual
// // theming systems such as "color" or "typography".
// $Kemar.GAS.UI-theme: mat-light-theme((
//   color: (
//     primary: $Kemar.GAS.UI-primary,
//     accent: $Kemar.GAS.UI-accent,
//     warn: $Kemar.GAS.UI-warn,
//   )
// ));

// // Include theme styles for core and each component used in your app.
// // Alternatively, you can import and @include the theme mixins for each component
// // that you are using.
// @include angular-material-theme($Kemar.GAS.UI-theme);

// @import "~@angular/material/prebuilt-themes/deeppurple-amber.css";
@import "~@angular/material/prebuilt-themes/indigo-pink.css";
@import "@ng-select/ng-select/themes/default.theme.css";

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
}


h1 {
  text-align: center;
}
.mat-dialog-container {

  padding: 0px !important;

}


/* Importing Bootstrap SCSS file. */
@import '~bootstrap/scss/bootstrap';
// @import "scss/main.scss";
@import "scss/main_ux.scss";
@import "assets/scss/landing.scss";

@import "scss/variables";



html, body { height: 100%; }

table tbody{
  th, td {
    font-family: 'Roboto', "Helvetica Neue", sans-serif;
    font-size: 13px;
  }
}


footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #0f75bc;
  // padding:0.2rem;
  span{
    color: #ffffff;
  }
  p {
      color: #ffffff;
      margin-bottom: 0!important;
  }
}

 .modal .modal-content .modal-header h4, .modal .modal-content .modal-header span{
  color: black !important;
}


.custom-accordion {
  .mat-content {
    overflow: inherit!important;
  }
}


.action {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
  margin: auto;
}

textarea{
  font-size: 0.75rem !important;
}

