// @import "variables";

$color_1: #202020;
$color_2: #00FFFF;
$color_3: #1d46f5;
$color_4: #ffffff;
$color_5: rgba(255, 255, 255, 0.7);
$color_6: #ff8260;
$color_7: #222222;
$color_8: #555555;
$color_9: #8b8b8b;
$color_10: #111;
$color_11: #011F79;
$color_12: #ddd;
$color_13: rgba(255, 255, 255, 0.4);
$color_14: #eee;
$color_15: #3c3c3c;
$color_16: #6ba7f5;
$color_17: #aaaaaa;
$color_18: #777777;
$color_19: #bababa;
$color_20: #ccc;
$color_21: #0d58ba;
$color_22: #FECA45;
$font-family_1: "Open Sans", sans-serif;
$font-family_2: "Roboto", sans-serif;
$font-family_3: "Poppins", sans-serif;
$font-family_4: 'Lato', sans-serif;;
$background-color_1: #00FFFF;
$background-color_2: #106eea;
$background-color_3: #00FFFF;
$background-color_4: #f1f6fe;
$background-color_5: rgba(255, 255, 255, 0.5);
$background-color_4: #FFB764;
$background-color_5: #FF646D;
$border-color_1: #fff;
$border-color_2: #106eea;
$border-color_5: #d1252e;
$border-top-color_1: #e2eefd;
$border-top-color_2: #eee;


.svg-icon {
	height: 44px;
	width: 44px;
	background-color: $color_3;
	-webkit-mask-size: contain;
	mask-size: contain;
	-webkit-mask-position: center;
	mask-position: center;
	-webkit-mask-repeat: no-repeat;
	mask-repeat: no-repeat;
    display: inline-flex;
	// mask-image: url("");
  }
  .logistics {
	@include svg-icon("../../assets/landing/img/icons/logistics.svg", 32px, 32px,   $color_3);
	// background-image: url(../../assets/landing/img/icons/logistics.svg)
  }
  .optimization {
	@include svg-icon("../../assets/landing/img/icons/optimization.svg", 32px, 32px,   $color_3);
  }
  .centralized {
	@include svg-icon("../../assets/landing/img/icons/centralized.svg", 32px, 32px,   $color_3);
  }
  .unmanned {
	@include svg-icon("../../assets/landing/img/icons/unmanned.svg", 32px, 32px,   $color_3);
  }
  .reducing {
	@include svg-icon("../../assets/landing/img/icons/reducing.svg", 32px, 32px,   $color_3);
  }
  .equipment {
	@include svg-icon("../../assets/landing/img/icons/equipment.svg", 32px, 32px,   $color_3);
  }

  @media only screen and (min-width: 1440px) {
	.container {
		max-width: 1400px;
	}
	#intro .img1 {
		max-width: 90%;
		float: right;
	}

  }

// @media (min-width: 1200px) {
// 	.container-xl, .container-lg, .container-md, .container-sm, .container {
//     max-width: 1200px;
// }
// }
// @media (min-width: 1200px) {
// 	.container-sm {
//     max-width: 980px;
// }
// }
.btn-red {
	font-weight: 500;
    font-size: 13px;
    display: flex;
    padding: 10px 40px;
    border-radius: 4px;
    transition: 0.5s;
    background: $color_6;
    border: solid 1px #d1252e;
    color: #ffffff;
    width: max-content;
    cursor: pointer;
	&:hover {
		background: #d1252e;
		color: #ffffff;
	}
}
.industries {
	.btn-primary {
		font-family: $font-family_4;
		font-weight: 500;
		font-size: 14px;
		letter-spacing: 1px;
		display: inline-block;
		padding: 10px 28px;
		border-radius: 4px;
		transition: 0.5s;
		color: $color_4;
		background: $color_3;
		border: solid 1px #0231be;
		width: auto;
		margin: 0 auto;
		cursor: pointer;
		&:hover {
			background: #0231be;
			color: #ffffff;
		}
	}
	.btn-light-outline {
		font-family: $font-family_4;
		// text-transform: uppercase;
		font-weight: 500;
		font-size: 14px;
		letter-spacing: 1px;
		display: inline-block;
		padding: 10px 28px;
		border-radius: 4px;
		transition: 0.5s;
		color: $color_2;
		background: transparent;
		border: solid 1px $color_2;
		width: auto;
		margin: 0 auto;
		cursor: pointer;
		&:hover {
			background: $background-color_1;
			color: #053FB0;
		}
	}
}


#preloader {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 9999;
	overflow: hidden;
	background: #fff;
	&:before {
		content: "";
		position: fixed;
		top: calc(50% - 30px);
		left: calc(50% - 30px);
		border: 6px solid #106eea;
		border-top-color: $border-top-color_1;
		border-radius: 50%;
		width: 60px;
		height: 60px;
		-webkit-animation: animate-preloader 1s linear infinite;
		animation: animate-preloader 1s linear infinite;
	}
}
.back-to-top {
	position: fixed;
	visibility: hidden;
	opacity: 0;
	right: 15px;
	bottom: 15px;
	z-index: 996;
	background: #106eea;
	width: 40px;
	height: 40px;
	border-radius: 4px;
	transition: all 0.4s;
	i {
		font-size: 28px;
		color: $color_4;
		line-height: 0;
	}
	&:hover {
		background: #3284f1;
		color: $color_4;
	}
}
.back-to-top.active {
	visibility: visible;
	opacity: 1;
}
#topbar {
	background: #002490;
	height: 28px;
	font-size: 12px;
	transition: all 0.5s;
	color: $color_1;
	padding: 0;
	.contact-info {
		i {
			font-style: normal;
			color: $color_4;
			a {
				padding-left: 5px;
				color: $color_4;
				line-height: 0;
				transition: 0.3s;
				transition: 0.3s;
				&:hover {
					color: $color_2;
					text-decoration: underline;
				}
			}
			span {
				padding-left: 5px;
				color: $color_4;
			}
		}
	}
	.social-links {
		a {
			color: $color_4;
			line-height: 0;
			transition: 0.3s;
			margin-left: 20px;
			&:hover {
				color: $color_2;
			}
		}
	}
}

// .btn-login {
//     color: #fff;
//     background-color: #FFB764;
//     border-color: #EE8A18;
//     padding: .5rem 2rem;
// 	font-size: 1rem;
// 	font-weight: 600;
// 	border-radius: 4rem!important;
// 	&:hover {
// 		background-color: #EE8A18;
// 	}
// }
// .rounded {
//     border-radius: 4rem!important;
// }

#header {
	position: absolute;
	left: 0;
	top: 0;
	z-index: 99;
	width: 100%;
	padding: 12px 0;
	-webkit-transition: 0.5s;
	transition: 0.5s;
	background: transparent;
	background-size: cover!important;
	.sticky {
		position: fixed;
		z-index: 999;
		background-color: #fff;
		padding: 16px 0;
		-webkit-box-shadow: 0 0 30px rgb(0 0 0 / 10%);
		box-shadow: 0 0 30px rgb(0 0 0 / 10%);
	}
	.logo {
		font-size: 30px;
		margin: 0;
		padding: 0;
		line-height: 1;
		font-weight: 600;
		letter-spacing: 0.8px;
		font-family: $font-family_1;
		a {
			font-weight: 700;
			font-size: 14px;
			display: flex;
			padding: 10px 40px;
			border-radius: 4px;
			transition: 0.5s;
			background: #F4643C;
			border: solid 1px #d1252e;
			width: max-content;
			color: $color_4!important;
			&:hover{
				color: $color_4!important;
			}
			span {
				color: $color_2;
			}
		}
		img {
			max-height: 70px;
		}
	}
	.login {
		border-radius: 4rem;
		padding: 8px 30px;
		color: $color_4!important;
		i {
			font-size: 18px;
			line-height: 0;
			margin-right: 4px;
		}
	}

}


// $background-color_1: white;
// $background-color_2: #d88683;

#dotsNav {
	position: fixed;
	right: 40px;
	top: 50%;
	bottom: auto;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	z-index: 1;
	li {
		text-align: right;
		list-style: none;
	}
	a {
		display: inline-block;
		-webkit-backface-visibility: hidden;
		backface-visibility: hidden;
		&:after {
			content: "";
			display: table;
			clear: both;
		}
		span {
			display: inline-block;
			float: right;
			-webkit-transform: scale(0.6);
			-moz-transform: scale(0.6);
			-ms-transform: scale(0.6);
			-o-transform: scale(0.6);
			transform: scale(0.6);
		}
		&:hover {
			span {
				-webkit-transform: scale(1);
				-moz-transform: scale(1);
				-ms-transform: scale(1);
				-o-transform: scale(1);
				transform: scale(1);
			}
			.cd-label {
				opacity: 1;
			}
		}
	}
	a.is-selected {
		.cd-dot {
			background-color: $color_6;
		}
	}
	.cd-dot {
		position: relative;
		top: 8px;
		height: 20px;
		width: 20px;
		border-radius: 50%;
		border: solid 1px #ffffff;
		background-color: $color_2;
		-webkit-transition: -webkit-transform 0.2s, background-color 0.5s;
		-moz-transition: -moz-transform 0.2s, background-color 0.5s;
		transition: transform 0.2s, background-color 0.5s;
		-webkit-transform-origin: 50% 50%;
		-moz-transform-origin: 50% 50%;
		-ms-transform-origin: 50% 50%;
		-o-transform-origin: 50% 50%;
		transform-origin: 50% 50%;
	}
	.cd-label {
		position: relative;
		margin-right: 0;
		padding: .4em .5em;
		color: $color_3;
		font-size: 14px;
		font-size: 0.875rem;
		-webkit-transition: -webkit-transform 0.2s, opacity 0.2s;
		-moz-transition: -moz-transform 0.2s, opacity 0.2s;
		transition: transform 0.2s, opacity 0.2s;
		opacity: 0;
		-webkit-transform-origin: 100% 50%;
		-moz-transform-origin: 100% 50%;
		-ms-transform-origin: 100% 50%;
		-o-transform-origin: 100% 50%;
		transform-origin: 100% 50%;
	}
}

#header.sticky {
	position: fixed;
	z-index: 999;
	background-color: $background-color_1;
	padding: 16px 0;
	-webkit-box-shadow: 0 0 30px rgb(0 0 0 / 10%);
	box-shadow: 0 0 30px rgb(0 0 0 / 10%);
}
.scrolled-offset {
	margin-top: 70px;
}


.navbar {
	padding: 0;
	ul {
		margin: 0;
		padding: 0;
		display: flex;
		list-style: none;
		align-items: center;
	}
	li {
		position: relative;
		&:hover {
			>a {
				&:before {
					visibility: visible;
					width: 100%;
				}
				color: $color_2;
			}
		}
	}
	>ul {
		>li {
			white-space: nowrap;
			padding: 10px 0 10px 28px;
			>a {
				&:before {
					content: "";
					position: absolute;
					width: 100%;
					height: 2px;
					bottom: -6px;
					left: 0;
					background-color: $background-color_1;
					visibility: hidden;
					width: 0px;
					transition: all 0.3s ease-in-out 0s;
				}
			}
		}
	}
	a {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 0 3px;
		font-size: 15px;
		font-weight: 600;
		color: $color_7;
		white-space: nowrap;
		transition: 0.3s;
		position: relative;
		&:focus {
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 0 3px;
			font-size: 15px;
			font-weight: 600;
			color: $color_7;
			white-space: nowrap;
			transition: 0.3s;
			position: relative;
			i {
				font-size: 12px;
				line-height: 0;
				margin-left: 5px;
			}
		}
		i {
			font-size: 12px;
			line-height: 0;
			margin-left: 5px;
		}
		&:hover {
			&:before {
				visibility: visible;
				width: 100%;
			}
			color: $color_2;
		}
	}
	.active {
		&:before {
			visibility: visible;
			width: 100%;
		}
		color: $color_2;
		&:focus {
			color: $color_2;
		}
	}
	.dropdown {
		ul {
			display: block;
			position: absolute;
			left: 28px;
			top: calc(100% + 30px);
			margin: 0;
			padding: 10px 0;
			z-index: 99;
			opacity: 0;
			visibility: hidden;
			background: #fff;
			box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
			transition: 0.3s;
			li {
				min-width: 200px;
				&:hover {
					>a {
						color: $color_2;
					}
				}
			}
			a {
				padding: 10px 20px;
				font-weight: 400;
				i {
					font-size: 12px;
				}
				&:hover {
					color: $color_2;
				}
			}
			.active {
				&:hover {
					color: $color_2;
				}
			}
		}
		&:hover {
			>ul {
				opacity: 1;
				top: 100%;
				visibility: visible;
			}
		}
		.dropdown {
			ul {
				top: 0;
				left: calc(100% - 30px);
				visibility: hidden;
			}
			&:hover {
				>ul {
					opacity: 1;
					top: 0;
					left: 100%;
					visibility: visible;
				}
			}
		}
	}
}
.mobile-nav-toggle {
	color: $color_7;
	font-size: 28px;
	cursor: pointer;
	display: none;
	line-height: 0;
	transition: 0.5s;
}
.mobile-nav-toggle.bi-x {
	color: $color_4;
}
.navbar-mobile {
	position: fixed;
	overflow: hidden;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	background: rgba(9, 9, 9, 0.9);
	transition: 0.3s;
	z-index: 999;
	.mobile-nav-toggle {
		position: absolute;
		top: 15px;
		right: 15px;
	}
	ul {
		display: block;
		position: absolute;
		top: 55px;
		right: 15px;
		bottom: 15px;
		left: 15px;
		padding: 10px 0;
		background-color: $background-color_2;
		overflow-y: auto;
		transition: 0.3s;
	}
	a {
		padding: 10px 20px;
		font-size: 15px;
		color: $color_7;
		&:focus {
			padding: 10px 20px;
			font-size: 15px;
			color: $color_7;
		}
		&:hover {
			&:before {
				visibility: hidden;
			}
			color: $color_2;
		}
	}
	>ul {
		>li {
			padding: 0;
		}
	}
	li {
		&:hover {
			>a {
				&:before {
					visibility: hidden;
				}
				color: $color_2;
			}
		}
	}
	.active {
		&:before {
			visibility: hidden;
		}
		color: $color_2;
	}
	.getstarted {
		margin: 15px;
		&:focus {
			margin: 15px;
		}
	}
	.dropdown {
		ul {
			position: static;
			display: none;
			margin: 10px 20px;
			padding: 10px 0;
			z-index: 99;
			opacity: 1;
			visibility: visible;
			background: #fff;
			box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
			li {
				min-width: 200px;
				&:hover {
					>a {
						color: $color_2;
					}
				}
			}
			a {
				padding: 10px 20px;
				i {
					font-size: 12px;
				}
				&:hover {
					color: $color_2;
				}
			}
			.active {
				&:hover {
					color: $color_2;
				}
			}
		}
		>.dropdown-active {
			display: block;
		}
	}
}
#intro {
	width: 100%;
	height: 100vh;
	background: url("../../assets/landing/img/bg-blue.jpg") top left;
	background-size: cover;
	position: relative;
	// &:before {
	// 	content: "";
	// 	background: rgba(255, 255, 255, 0.6);
	// 	position: absolute;
	// 	bottom: 0;
	// 	top: 0;
	// 	left: 0;
	// 	right: 0;
	// }
	.container {
		position: relative;
		width: 80%;
		margin-top: -2rem;
	}
	.get-started {
		margin-top: 1.5rem;
	}
	.img1 {
		max-width: 80%;
		float: right;
	}
	h1 {
		font-size: 42px;
		// font-weight: normal;
		line-height: 42px;
		color: $color_4;
		font-family: $font-family_4;
        text-transform: uppercase;
		margin-bottom: 1rem;
		text-align: left;
		strong {
			color:$color_6;
			font-weight: 700;
		}
	}
	h2 {
		color: $color_2;
		font-size: 21px!important;
		font-weight: normal;
		margin-bottom: 0!important;
	}
	h3 {
		color: $color_4;
		margin-bottom: 1rem;
		font-size: 16px;
		font-weight: normal;
		line-height: normal;
	}
	.btn-get-started {
		font-family: $font-family_4;
		// text-transform: uppercase;
		font-weight: 500;
		font-size: 14px;
		letter-spacing: 1px;
		display: inline-block;
		padding: 10px 28px;
		border-radius: 4px;
		transition: 0.5s;
		color: $color_2;
		background: transparent;
		border: solid 1px $color_2;
		text-decoration: none;
		&:hover {
			background: $background-color_1;
			color: #053FB0;
		}
	}
	.btn-watch-video {
		font-size: 16px;
		transition: 0.5s;
		margin-left: 25px;
		color: $color_7;
		font-weight: 600;
		display: flex;
		align-items: center;
		i {
			color: $color_2;
			font-size: 32px;
			transition: 0.3s;
			line-height: 0;
			margin-right: 8px;
		}
		&:hover {
			color: $color_2;
			i {
				color: $color_3;
			}
		}
	}
}
.title-two {
    color: $color_3;
    font-size: 18px;
    font-weight: normal;
    margin-bottom: 0;
}
.product-featured {
	position: relative;
	padding: 3rem 1rem;
	z-index: 1;
	box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	.icon-box {
		padding: 1rem 0.25rem;
		text-align: start;
		// margin-bottom: 1rem;
		.featured-icon {
			// box-shadow: 0 0 4px rgb(29 70 245 / 80%);
			margin: 0 auto;
			width: 60px;
			min-width: 60px;
			height: 60px;
			line-height: 60px;
			background: #f1f6fe;
			border-radius: 4rem;
			border: 1px solid #deebfd;
			display: flex;
			align-items: center;
			justify-content: center;
			// margin-bottom: 3rem;
			transition: ease-in-out 0.3s;
			margin-right: 1rem;

			img {
				max-width: 60%;
				// background: #fff;
				// padding: 0.25rem;
				// margin-right: 1rem;
				// border-radius: 5rem;
				// line-height: 50px;
				// height: 50px;
				// width: 50px;
				// border: solid 1px #FBAD4C;
			}
		}
		h3 {
			font-size: 1.2em;
			color: $color_1;
			margin-bottom: 0;
			font-weight: 700;
			line-height: normal;
		}
		p {
			font-size: 0.95em;
			color: $color_1;
			margin-bottom: 0.875rem;
			font-family: $font-family_2;
			// text-align: justify;
		}
		.color_1 { border: solid 1px #FF646D;}
		.color_2 { border: solid 1px #59D05D;}
		.color_3 { border: solid 1px #00FFFF;}
		.color_4 { border: solid 1px #0d58ba;}
		.color_5 { border: solid 1px #FBAD4C;}
		.color_6 { border: solid 1px #168DA9;}
	}

}
.title1 {
	font-size: 32px;
	font-weight: 600;
	color: $color_1;
}
#main-content {
	margin-top: -10rem;
	// background-color: #FAFCFE;
	background-color: #f0f1f2;
}
.section-bg {
	background-color: $background-color_3;
}
.section-title {
	text-align: center;
	// padding-bottom: 30px;
	h2 {
		font-size: 13px;
		letter-spacing: 1px;
		font-weight: 700;
		padding: 8px 20px;
		margin: 0;
		background: #e7f1fd;
		color: $color_2;
		display: inline-block;
		text-transform: uppercase;
		border-radius: 50px;
	}
	h3 {
		margin: 15px 0 0 0;
		font-size: 32px;
		font-weight: 700;
		span {
			color: $color_2;
		}
	}
	p {
		margin: 15px auto 0 auto;
		font-weight: 600;
	}
}
.breadcrumbs {
	padding: 20px 0;
	background-color: $background-color_4;
	min-height: 40px;
	h2 {
		font-size: 24px;
		font-weight: 300;
		margin: 0;
	}
	ol {
		display: flex;
		flex-wrap: wrap;
		list-style: none;
		padding: 0;
		margin: 0;
		font-size: 14px;
		li+li {
			padding-left: 10px;
			&::before {
				display: inline-block;
				padding-right: 10px;
				color: $color_9;
				content: "/";
			}
		}
	}
}
.featured-services {
	.icon-box {
		padding: 30px;
		position: relative;
		overflow: hidden;
		background: #fff;
		box-shadow: 0 0 29px 0 rgba(68, 88, 144, 0.12);
		transition: all 0.3s ease-in-out;
		border-radius: 8px;
		z-index: 1;
		&::before {
			content: "";
			position: absolute;
			background: #cbe0fb;
			right: 0;
			left: 0;
			bottom: 0;
			top: 100%;
			transition: all 0.3s;
			z-index: -1;
		}
		&:hover {
			&::before {
				background: #106eea;
				top: 0;
				border-radius: 0px;
			}
			.title {
				a {
					color: $color_4;
				}
			}
			.description {
				color: $color_4;
			}
			.icon {
				i {
					color: $color_4;
				}
			}
		}
	}
	.icon {
		margin-bottom: 15px;
		i {
			font-size: 48px;
			line-height: 1;
			color: $color_2;
			transition: all 0.3s ease-in-out;
		}
	}
	.title {
		font-weight: 700;
		margin-bottom: 15px;
		font-size: 18px;
		a {
			color: $color_10;
		}
	}
	.description {
		font-size: 15px;
		line-height: 28px;
		margin-bottom: 0;
	}
}
.product-details, .Featured {
	.content {
		h3 {
			font-weight: 600;
			font-size: 26px;
		}
		ul {
			list-style: none;
			padding-left: 15px;
			margin-top: 10px;
			li {
				display: flex;
				align-items: center;
				margin-bottom: 0;
				i{
					font-size: 0.85rem;
				}
				&:first-child {
					margin-top: 10px;
				}
			}
			img {
				background: #fff;
				padding: 0.75rem;
				margin-right: 15px;
				border-radius: 5rem;
				line-height: 72px;
				height: 72px;
				width: 72px;
				border: solid 1px #FBAD4C;
			}
			h5 {
				font-size: 14px;
				color: #484848;
				margin-bottom: 0;
				margin-left: 8px;
				font-weight: 400;
				line-height: normal;
			}
			p {
				font-size: 16px;
			}
		}
		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}
}
.skills {
	.progress {
		height: 60px;
		display: block;
		background: none;
		border-radius: 0;
		.skill {
			padding: 0;
			margin: 0 0 6px 0;
			text-transform: uppercase;
			display: block;
			font-weight: 600;
			font-family: $font-family_3;
			color: $color_7;
			.val {
				float: right;
				font-style: normal;
			}
		}
	}
	.progress-bar-wrap {
		background: #e2eefd;
		height: 10px;
	}
	.progress-bar {
		width: 1px;
		height: 10px;
		transition: 0.9s;
		background-color: $background-color_1;
	}
}
.counts {
	padding: 70px 0 60px;
	.count-box {
		padding: 30px 30px 25px 30px;
		width: 100%;
		position: relative;
		text-align: center;
		background: #f1f6fe;
		i {
			position: absolute;
			top: -28px;
			left: 50%;
			transform: translateX(-50%);
			font-size: 24px;
			background: #106eea;
			color: $color_4;
			width: 56px;
			height: 56px;
			line-height: 0;
			border-radius: 50px;
			border: 5px solid #fff;
			display: inline-flex;
			align-items: center;
			justify-content: center;
		}
		span {
			font-size: 36px;
			display: block;
			font-weight: 600;
			color: $color_11;
		}
		p {
			padding: 0;
			margin: 0;
			font-family: $font-family_2;
			font-size: 14px;
		}
	}
}
.clients {
	padding: 15px 0;
	text-align: center;
	img {
		max-width: 45%;
		transition: all 0.4s ease-in-out;
		display: inline-block;
		padding: 15px 0;
		&:hover {
			transform: scale(1.15);
		}
	}
}


// .portfolio {
// 	#portfolio-flters {
// 		padding: 0;
// 		margin: 0 auto 15px auto;
// 		list-style: none;
// 		text-align: center;
// 		border-radius: 50px;
// 		padding: 2px 15px;
// 		li {
// 			cursor: pointer;
// 			display: inline-block;
// 			padding: 10px 15px 8px 15px;
// 			font-size: 16px;
// 			font-weight: 600;
// 			line-height: 1;
// 			text-transform: uppercase;
// 			color: $color_1;
// 			margin-bottom: 5px;
// 			transition: all 0.3s ease-in-out;
// 			&:hover {
// 				color: $color_2;
// 			}
// 			&:last-child {
// 				margin-right: 0;
// 			}
// 		}
// 		li.filter-active {
// 			color: $color_2;
// 		}
// 	}
// 	.portfolio-item {
// 		margin-bottom: 30px;
// 		.portfolio-info {
// 			opacity: 0;
// 			position: absolute;
// 			left: 30px;
// 			right: 30px;
// 			bottom: 0;
// 			z-index: 3;
// 			transition: all ease-in-out 0.3s;
// 			background: rgba(255, 255, 255, 0.9);
// 			padding: 15px;
// 			h4 {
// 				font-size: 18px;
// 				color: $color_4;
// 				font-weight: 600;
// 				color: $color_7;
// 			}
// 			p {
// 				color: $color_8;
// 				font-size: 14px;
// 				margin-bottom: 0;
// 			}
// 			.preview-link {
// 				position: absolute;
// 				right: 40px;
// 				font-size: 24px;
// 				top: calc(50% - 18px);
// 				color: $color_15;
// 				&:hover {
// 					color: $color_2;
// 				}
// 			}
// 			.details-link {
// 				position: absolute;
// 				right: 40px;
// 				font-size: 24px;
// 				top: calc(50% - 18px);
// 				color: $color_15;
// 				right: 10px;
// 				&:hover {
// 					color: $color_2;
// 				}
// 			}
// 		}
// 		.portfolio-links {
// 			opacity: 0;
// 			left: 0;
// 			right: 0;
// 			text-align: center;
// 			z-index: 3;
// 			position: absolute;
// 			transition: all ease-in-out 0.3s;
// 			a {
// 				color: $color_4;
// 				margin: 0 2px;
// 				font-size: 28px;
// 				display: inline-block;
// 				transition: 0.3s;
// 				&:hover {
// 					color: $color_16;
// 				}
// 			}
// 		}
// 		&:hover {
// 			.portfolio-info {
// 				opacity: 1;
// 				bottom: 20px;
// 			}
// 		}
// 	}
// }
.portfolio-details {
	padding-top: 40px;
	.portfolio-details-slider {
		img {
			width: 100%;
		}
		.swiper-pagination {
			margin-top: 20px;
			position: relative;
			.swiper-pagination-bullet {
				width: 12px;
				height: 12px;
				background-color: $background-color_2;
				opacity: 1;
				border: 1px solid #106eea;
			}
			.swiper-pagination-bullet-active {
				background-color: $background-color_1;
			}
		}
	}
	.portfolio-info {
		padding: 30px;
		box-shadow: 0px 0 30px rgba(34, 34, 34, 0.08);
		h3 {
			font-size: 22px;
			font-weight: 700;
			margin-bottom: 20px;
			padding-bottom: 20px;
			border-bottom: 1px solid #eee;
		}
		ul {
			list-style: none;
			padding: 0;
			font-size: 15px;
			li+li {
				margin-top: 10px;
			}
		}
	}
	.portfolio-description {
		padding-top: 30px;
		h2 {
			font-size: 26px;
			font-weight: 700;
			margin-bottom: 20px;
		}
		p {
			padding: 0;
		}
	}
}
.team {
	padding: 60px 0;
	.member {
		margin-bottom: 20px;
		overflow: hidden;
		border-radius: 4px;
		background: #fff;
		box-shadow: 0px 2px 15px rgba(16, 110, 234, 0.15);
		.member-img {
			position: relative;
			overflow: hidden;
		}
		.social {
			position: absolute;
			left: 0;
			bottom: 30px;
			right: 0;
			opacity: 0;
			transition: ease-in-out 0.3s;
			text-align: center;
			a {
				transition: color 0.3s;
				color: $color_7;
				margin: 0 3px;
				padding-top: 7px;
				border-radius: 4px;
				width: 36px;
				height: 36px;
				background: rgba(16, 110, 234, 0.8);
				display: inline-block;
				transition: ease-in-out 0.3s;
				color: $color_4;
				&:hover {
					background: #3b8af2;
				}
			}
			i {
				font-size: 18px;
			}
		}
		.member-info {
			padding: 25px 15px;
			h4 {
				font-weight: 700;
				margin-bottom: 5px;
				font-size: 18px;
				color: $color_7;
			}
			span {
				display: block;
				font-size: 13px;
				font-weight: 400;
				color: $color_17;
			}
			p {
				font-style: italic;
				font-size: 14px;
				line-height: 26px;
				color: $color_18;
			}
		}
		&:hover {
			.social {
				opacity: 1;
				bottom: 15px;
			}
		}
	}
}
.pricing {
	.box {
		padding: 20px;
		background: #fff;
		text-align: center;
		box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.12);
		border-radius: 5px;
		position: relative;
		overflow: hidden;
		h3 {
			font-weight: 400;
			margin: -20px -20px 20px -20px;
			padding: 20px 15px;
			font-size: 16px;
			font-weight: 600;
			color: $color_18;
			background: #f8f8f8;
		}
		h4 {
			font-size: 36px;
			color: $color_2;
			font-weight: 600;
			font-family: $font-family_3;
			margin-bottom: 20px;
			sup {
				font-size: 20px;
				top: -15px;
				left: -3px;
			}
			span {
				color: $color_19;
				font-size: 16px;
				font-weight: 300;
			}
		}
		ul {
			padding: 0;
			list-style: none;
			color: $color_1;
			text-align: center;
			line-height: 20px;
			font-size: 14px;
			li {
				padding-bottom: 16px;
			}
			i {
				color: $color_2;
				font-size: 18px;
				padding-right: 4px;
			}
			.na {
				color: $color_20;
				text-decoration: line-through;
			}
		}
	}
	.btn-wrap {
		margin: 20px -20px -20px -20px;
		padding: 20px 15px;
		background: #f8f8f8;
		text-align: center;
	}
	.btn-buy {
		background: #106eea;
		display: inline-block;
		padding: 8px 35px 10px 35px;
		border-radius: 4px;
		color: $color_4;
		transition: none;
		font-size: 14px;
		font-weight: 400;
		font-family: $font-family_2;
		font-weight: 600;
		transition: 0.3s;
		&:hover {
			background: #3b8af2;
		}
	}

	.advanced {
		width: 200px;
		position: absolute;
		top: 18px;
		right: -68px;
		transform: rotate(45deg);
		z-index: 1;
		font-size: 14px;
		padding: 1px 0 3px 0;
		background: #106eea;
		color: $color_4;
	}
}
.faq {
	padding: 60px 0;
	.faq-list {
		padding: 0;
		list-style: none;
		li {
			border-bottom: 1px solid #d4e5fc;
			margin-bottom: 20px;
			padding-bottom: 20px;
		}
		.question {
			display: block;
			position: relative;
			font-family: $font-family_4;
			font-size: 18px;
			line-height: 24px;
			font-weight: 400;
			padding-left: 25px;
			cursor: pointer;
			color: $color_21;
			transition: 0.3s;
		}
		i {
			font-size: 16px;
			position: absolute;
			left: 0;
			top: -2px;
		}
		p {
			margin-bottom: 0;
			padding: 10px 0 0 25px;
		}
		.icon-show {
			display: none;
		}
		.collapsed {
			color: $color_22;
			&:hover {
				color: $color_2;
			}
			.icon-show {
				display: inline-block;
				transition: 0.6s;
			}
			.icon-close {
				display: none;
				transition: 0.6s;
			}
		}
	}
}
.contact {
	.info-box {
		color: $color_1;
		text-align: center;
		box-shadow: 0 0 30px rgba(214, 215, 216, 0.3);
		padding: 20px 0 30px 0;
		i {
			font-size: 32px;
			color: $color_2;
			border-radius: 50%;
			padding: 8px;
			border: 2px dotted #b3d1fa;
		}
		h3 {
			font-size: 20px;
			color: $color_18;
			font-weight: 700;
			margin: 10px 0;
		}
		p {
			padding: 0;
			line-height: 24px;
			font-size: 14px;
			margin-bottom: 0;
		}
	}
	.php-email-form {
		box-shadow: 0 0 30px rgba(214, 215, 216, 0.4);
		padding: 30px;
		.error-message {
			display: none;
			color: $color_4;
			background: #ed3c0d;
			text-align: left;
			padding: 15px;
			font-weight: 600;
			br+br {
				margin-top: 25px;
			}
		}
		.sent-message {
			display: none;
			color: $color_4;
			background: #18d26e;
			text-align: center;
			padding: 15px;
			font-weight: 600;
		}
		.loading {
			display: none;
			background: #fff;
			text-align: center;
			padding: 15px;
			&:before {
				content: "";
				display: inline-block;
				border-radius: 50%;
				width: 24px;
				height: 24px;
				margin: 0 10px -6px 0;
				border: 3px solid #18d26e;
				border-top-color: $border-top-color_2;
				-webkit-animation: animate-loading 1s linear infinite;
				animation: animate-loading 1s linear infinite;
			}
		}
		.form-group {
			margin-bottom: 20px;
		}
		input {
			border-radius: 0;
			box-shadow: none;
			font-size: 14px;
			padding: 10px 15px;
			&:focus {
				border-color: $border-color_2;
			}
		}
		textarea {
			border-radius: 0;
			box-shadow: none;
			font-size: 14px;
			padding: 12px 15px;
			&:focus {
				border-color: $border-color_2;
			}
		}
		button[type=submit] {
			background: #106eea;
			border: 0;
			padding: 10px 30px;
			color: $color_4;
			transition: 0.4s;
			border-radius: 4px;
			&:hover {
				background: #3b8af2;
			}
		}
	}
}
#footer {
    padding: 0.5rem;
    color: #202020;
    font-size: 12px;
    background: #f1f6fe;
    display: flex;
    align-items: center;
	.footer-newsletter {
		padding: 50px 0;
		background: #f1f6fe;
		text-align: center;
		font-size: 15px;
		h4 {
			font-size: 24px;
			margin: 0 0 20px 0;
			padding: 0;
			line-height: 1;
			font-weight: 600;
		}
		form {
			margin-top: 30px;
			background: #fff;
			padding: 6px 10px;
			position: relative;
			border-radius: 4px;
			box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.06);
			text-align: left;
			input[type=email] {
				border: 0;
				padding: 4px 8px;
				width: calc(100% - 100px);
			}
			input[type=submit] {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				border: 0;
				background: none;
				font-size: 16px;
				padding: 0 20px;
				background: #106eea;
				color: $color_4;
				transition: 0.3s;
				border-radius: 0 4px 4px 0;
				box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
				&:hover {
					background: #0d58ba;
				}
			}
		}
	}
	.footer-top {
		padding: 60px 0 30px 0;
		background: #fff;
		.footer-contact {
			margin-bottom: 30px;
			h3 {
				font-size: 24px;
				margin: 0 0 15px 0;
				padding: 2px 0 2px 0;
				line-height: 1;
				font-weight: 700;
				span {
					color: $color_2;
				}
			}
			p {
				font-size: 14px;
				line-height: 24px;
				margin-bottom: 0;
				font-family: $font-family_2;
				color: $color_18;
			}
		}
		h4 {
			font-size: 16px;
			font-weight: bold;
			color: $color_1;
			position: relative;
			padding-bottom: 12px;
		}
		.footer-links {
			margin-bottom: 30px;
			ul {
				list-style: none;
				padding: 0;
				margin: 0;
				i {
					padding-right: 2px;
					color: $color_2;
					font-size: 18px;
					line-height: 1;
				}
				li {
					padding: 10px 0;
					display: flex;
					align-items: center;
					&:first-child {
						padding-top: 0;
					}
				}
				a {
					color: $color_18;
					transition: 0.3s;
					display: inline-block;
					line-height: 1;
					&:hover {
						text-decoration: none;
						color: $color_2;
					}
				}
			}
		}
		.social-links {
			a {
				font-size: 18px;
				display: inline-block;
				background: #106eea;
				color: $color_4;
				line-height: 1;
				padding: 8px 0;
				margin-right: 4px;
				border-radius: 4px;
				text-align: center;
				width: 36px;
				height: 36px;
				transition: 0.3s;
				&:hover {
					background: #3b8af2;
					color: $color_4;
					text-decoration: none;
				}
			}
		}
	}
	.copyright {
		text-align: center;
		// float: left;
	}
	.credits {
		float: right;
		text-align: center;
		font-size: 12px;
		color: $color_1;
	}
}
@media screen and (max-width: 768px) {
	[data-aos-delay] {
		transition-delay: 0 !important;
	}
}
@media (max-width: 1366px) {
	.navbar {
		.dropdown {
			.dropdown {
				ul {
					left: -90%;
				}
				&:hover {
					>ul {
						left: -100%;
					}
				}
			}
		}
	}
}
@media (max-width: 991px) {
	.mobile-nav-toggle {
		display: block;
	}
	.navbar {
		ul {
			display: none;
		}
	}
}
@media (min-width: 1024px) {
	#intro {
		background-attachment: fixed;
	}
	.section-title {
		p {
			width: 50%;
		}
	}
}
@media (max-width: 768px) {
	#intro {
		height: 100vh;
		h1 {
			font-size: 28px;
			line-height: 36px;
		}
		h2 {
			font-size: 18px;
			line-height: 24px;
			margin-bottom: 30px;
		}
		.btn-get-started {
			font-size: 13px;
		}
		.btn-watch-video {
			font-size: 13px;
		}
	}
	.breadcrumbs {
		.d-flex {
			display: block !important;
		}
		ol {
			display: block;
			li {
				display: inline-block;
			}
		}
	}
	.clients {
		img {
			max-width: 40%;
		}
	}
	#footer {
		.copyright {
			float: none;
			text-align: center;
			padding: 2px 0;
		}
		.credits {
			float: none;
			text-align: center;
			padding: 2px 0;
		}
	}
}
@media (max-height: 500px) {
	#intro {
		height: 120vh;
	}
}
@media (max-width: 992px) {
	.breadcrumbs {
		h2 {
			margin: 0 0 10px 0;
		}
	}
}

.btn-details {
	padding: 0.5rem 1rem;
}
.btn-get-details {
	font-family: $font-family_4;
	// text-transform: uppercase;
	font-weight: 500;
	font-size: 13px;
	letter-spacing: 1px;
	display: inline-block;
	padding: 0.25rem 1rem;
	border-radius: 50px;
	transition: 0.5s;
	color: $color_3;
	background: transparent;
	border: solid 1px $color_3;
	&:hover {
		background: $background-color_1;
		color: #053FB0;
	}
}





	// #dotsNav {
	// 	position: static;
	// 	height: auto;
	// 	width: 100%;
	// 	max-width: none;
	// 	background-color: $background-color_1;
	// 	border-radius: 0;
	// 	-webkit-transform: scale(1);
	// 	-moz-transform: scale(1);
	// 	-ms-transform: scale(1);
	// 	-o-transform: scale(1);
	// 	transform: scale(1);
	// 	&::before {
	// 		display: none;
	// 	}
	// 	ul {
	// 		&::after {
	// 			clear: both;
	// 			content: "";
	// 			display: table;
	// 		}
	// 	}
	// 	li {
	// 		display: inline-block;
	// 		float: left;
	// 	}
	// 	a {
	// 		padding: 0 1em;
	// 		margin: 1.5em 1em;
	// 		color: $color_1;
	// 		&::after {
	// 			display: none;
	// 		}
	// 		&::before {
	// 			display: none;
	// 		}
	// 	}
	// 	a.active {
	// 		color: $color_2;
	// 	}
	// 	.label {
	// 		-webkit-transform: translateX(0);
	// 		-moz-transform: translateX(0);
	// 		-ms-transform: translateX(0);
	// 		-o-transform: translateX(0);
	// 		transform: translateX(0);
	// 	}
	// }







/* --------------------------------

Navigation trigger - visible on small devices only

-------------------------------- */
.cd-nav-trigger {
  display: block;
  position: fixed;
  z-index: 2;
  bottom: 30px;
  right: 5%;
  height: 44px;
  width: 44px;
  border-radius: 0.25em;
  background: rgba(234, 242, 227, 0.9);
  /* reset button style */
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  -o-appearance: none;
  appearance: none;
  border: none;
  outline: none;
}
.cd-nav-trigger span {
  /* central dot */
  position: absolute;
  height: 4px;
  width: 4px;
  background-color: #3a2c41;
  border-radius: 50%;
  left: 50%;
  top: 50%;
  bottom: auto;
  right: auto;
  -webkit-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -ms-transform: translateX(-50%) translateY(-50%);
  -o-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}
.cd-nav-trigger span::before, .cd-nav-trigger span::after {
  /* bottom and top dots */
  content: '';
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #3a2c41;
  border-radius: inherit;
}
.cd-nav-trigger span::before {
  top: -9px;
}
.cd-nav-trigger span::after {
  bottom: -9px;
}
@media only screen and (min-width: 800px) {
  .cd-nav-trigger {
    display: none;
  }
}

/* --------------------------------

Vertical navigation

-------------------------------- */
.cd-vertical-nav {
  position: fixed;
  z-index: 1;
  right: 5%;
  bottom: 30px;
  width: 90%;
  max-width: 400px;
  max-height: 90%;
  overflow-y: auto;
  -webkit-transform: scale(0);
  -moz-transform: scale(0);
  -ms-transform: scale(0);
  -o-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: right bottom;
  -moz-transform-origin: right bottom;
  -ms-transform-origin: right bottom;
  -o-transform-origin: right bottom;
  transform-origin: right bottom;
  -webkit-transition: -webkit-transform 0.2s;
  -moz-transition: -moz-transform 0.2s;
  transition: transform 0.2s;
  border-radius: 0.25em;
  background-color: rgba(234, 242, 227, 0.9);
}
.cd-vertical-nav a {
  display: block;
  padding: 1em;
  color: #3a2c41;
  font-weight: bold;
  border-bottom: 1px solid rgba(58, 44, 65, 0.1);
}
.cd-vertical-nav a.active {
  color: $color_4;
}
.cd-vertical-nav.open {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
  -webkit-overflow-scrolling: touch;
}
.cd-vertical-nav.open + .cd-nav-trigger {
  background-color: transparent;
}
.cd-vertical-nav.open + .cd-nav-trigger span {
  background-color: rgba(58, 44, 65, 0);
}
.cd-vertical-nav.open + .cd-nav-trigger span::before, .cd-vertical-nav.open + .cd-nav-trigger span::after {
  /* transform dots in 'X' icon */
  height: 3px;
  width: 20px;
  border-radius: 0;
  left: -8px;
}
.cd-vertical-nav.open + .cd-nav-trigger span::before {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
  top: 1px;
}
.cd-vertical-nav.open + .cd-nav-trigger span::after {
  -webkit-transform: rotate(135deg);
  -moz-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  bottom: 0;
}
@media only screen and (min-width: 800px) {
  .cd-vertical-nav {
    /* reset style */
    right: 0;
    top: 0;
    bottom: auto;
    height: 100vh;
    width: 60px;
    max-width: none;
    max-height: none;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1);
    background-color: transparent;
    /* vertically align its content*/
    display: table;
  }
  .cd-vertical-nav::before {
    /* this is the navigation background */
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.98);
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: -webkit-transform 0.4s;
    -moz-transition: -moz-transform 0.4s;
    transition: transform 0.4s;
  }
  .no-touch .cd-vertical-nav:hover::before, .touch .cd-vertical-nav::before {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
  .cd-vertical-nav ul {
    /* vertically align <ul> inside <nav>*/
    display: table-cell;
    vertical-align: middle;
    text-align: center;
	list-style: none;
	padding: 0;
  }
  .cd-vertical-nav a {
    position: relative;
    padding: 3em 0 0;
    margin: 1em auto;
    border-bottom: none;
    font-size: 0.7rem;
    color: $color_4;
	font-weight: normal;
  }
  .cd-vertical-nav a.active {
    color: $color_4;
  }
  .cd-vertical-nav a::before, .cd-vertical-nav a::after {
    /* used to create the filled circle and the background icon */
    content: '';
    position: absolute;
    left: 50%;
    /* Force Hardware Acceleration */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
  }
  .cd-vertical-nav a::before {
    /* filled circle */
    top: 0;
    height: 32px;
    width: 32px;
    border-radius: 50%;
    background: #dee2e6;
    -webkit-transform: translateX(-50%) scale(0.25);
    -moz-transform: translateX(-50%) scale(0.25);
    -ms-transform: translateX(-50%) scale(0.25);
    -o-transform: translateX(-50%) scale(0.25);
    transform: translateX(-50%) scale(0.25);
    -webkit-transition: -webkit-transform 0.4s, background-color 0.2s;
    -moz-transition: -moz-transform 0.4s, background-color 0.2s;
    transition: transform 0.4s, background-color 0.2s;
  }
  .cd-vertical-nav a::after {
    /* icon */
    opacity: 0;
    top: 8px;
    height: 16px;
    width: 16px;
    background: url(../../assets/landing/img/icons/nav-icons.svg) no-repeat;
    -webkit-transform: translateX(-50%) scale(0);
    -moz-transform: translateX(-50%) scale(0);
    -ms-transform: translateX(-50%) scale(0);
    -o-transform: translateX(-50%) scale(0);
    transform: translateX(-50%) scale(0);
    -webkit-transition: -webkit-transform 0.4s 0s, opacity 0.1s 0.3s;
    -moz-transition: -moz-transform 0.4s 0s, opacity 0.1s 0.3s;
    transition: transform 0.4s 0s, opacity 0.1s 0.3s;
  }
  .cd-vertical-nav a.active::before, .no-touch .cd-vertical-nav a:hover::before {
    background-color: $color_6;
  }
  .cd-vertical-nav li:first-of-type a::after {
    /* change icon using image sprites */
    background-position: 0px 0;
    /* fix bug with transitions - set scale(0.01) rather than scale(0) */
    -webkit-transform: translateX(-50%) translateY(4.5em) scale(0.01);
    -moz-transform: translateX(-50%) translateY(4.5em) scale(0.01);
    -ms-transform: translateX(-50%) translateY(4.5em) scale(0.01);
    -o-transform: translateX(-50%) translateY(4.5em) scale(0.01);
    transform: translateX(-50%) translateY(4.5em) scale(0.01);
  }
  .cd-vertical-nav li:first-of-type a::before {
    -webkit-transform: translateX(-50%) translateY(4.5em) scale(0.35);
    -moz-transform: translateX(-50%) translateY(4.5em) scale(0.35);
    -ms-transform: translateX(-50%) translateY(4.5em) scale(0.35);
    -o-transform: translateX(-50%) translateY(4.5em) scale(0.35);
    transform: translateX(-50%) translateY(4.5em) scale(0.35);
  }
  .cd-vertical-nav li:nth-of-type(2) a::after {
    background-position: 0px -16px;
    -webkit-transform: translateX(-50%) translateY(1.5em) scale(0.01);
    -moz-transform: translateX(-50%) translateY(1.5em) scale(0.01);
    -ms-transform: translateX(-50%) translateY(1.5em) scale(0.01);
    -o-transform: translateX(-50%) translateY(1.5em) scale(0.01);
    transform: translateX(-50%) translateY(1.5em) scale(0.01);
  }
  .cd-vertical-nav li:nth-of-type(2) a::before {
    -webkit-transform: translateX(-50%) translateY(1.5em) scale(0.35);
    -moz-transform: translateX(-50%) translateY(1.5em) scale(0.35);
    -ms-transform: translateX(-50%) translateY(1.5em) scale(0.35);
    -o-transform: translateX(-50%) translateY(1.5em) scale(0.35);
    transform: translateX(-50%) translateY(1.5em) scale(0.35);
  }
  .cd-vertical-nav li:nth-of-type(3) a::after {
    background-position: 0px -32px;
    -webkit-transform: translateX(-50%) translateY(-1.5em) scale(0.01);
    -moz-transform: translateX(-50%) translateY(-1.5em) scale(0.01);
    -ms-transform: translateX(-50%) translateY(-1.5em) scale(0.01);
    -o-transform: translateX(-50%) translateY(-1.5em) scale(0.01);
    transform: translateX(-50%) translateY(-1.5em) scale(0.01);
  }
  .cd-vertical-nav li:nth-of-type(3) a::before {
    -webkit-transform: translateX(-50%) translateY(-1.5em) scale(0.35);
    -moz-transform: translateX(-50%) translateY(-1.5em) scale(0.35);
    -ms-transform: translateX(-50%) translateY(-1.5em) scale(0.35);
    -o-transform: translateX(-50%) translateY(-1.5em) scale(0.35);
    transform: translateX(-50%) translateY(-1.5em) scale(0.35);
  }
  .cd-vertical-nav li:nth-of-type(4) a::after {
    background-position: 0px -48px;
    -webkit-transform: translateX(-50%) translateY(-4.5em) scale(0.01);
    -moz-transform: translateX(-50%) translateY(-4.5em) scale(0.01);
    -ms-transform: translateX(-50%) translateY(-4.5em) scale(0.01);
    -o-transform: translateX(-50%) translateY(-4.5em) scale(0.01);
    transform: translateX(-50%) translateY(-4.5em) scale(0.01);
  }
  .cd-vertical-nav li:nth-of-type(4) a::before {
    -webkit-transform: translateX(-50%) translateY(-4.5em) scale(0.35);
    -moz-transform: translateX(-50%) translateY(-4.5em) scale(0.35);
    -ms-transform: translateX(-50%) translateY(-4.5em) scale(0.35);
    -o-transform: translateX(-50%) translateY(-4.5em) scale(0.35);
    transform: translateX(-50%) translateY(-4.5em) scale(0.35);
  }

  .cd-vertical-nav li:nth-of-type(5) a::after {
    background-position: 0px -64px;
    -webkit-transform: translateX(-50%) translateY(-7.5em) scale(0.01);
    -moz-transform: translateX(-50%) translateY(-7.5em) scale(0.01);
    -ms-transform: translateX(-50%) translateY(-7.5em) scale(0.01);
    -o-transform: translateX(-50%) translateY(-7.5em) scale(0.01);
    transform: translateX(-50%) translateY(-7.5em) scale(0.01);
  }
  .cd-vertical-nav li:nth-of-type(5) a::before {
    -webkit-transform: translateX(-50%) translateY(-7.5em) scale(0.35);
    -moz-transform: translateX(-50%) translateY(-7.5em) scale(0.35);
    -ms-transform: translateX(-50%) translateY(-7.5em) scale(0.35);
    -o-transform: translateX(-50%) translateY(-7.5em) scale(0.35);
    transform: translateX(-50%) translateY(-7.5em) scale(0.35);
  }
  .cd-vertical-nav li:nth-of-type(6) a::after {
    background-position: 0px -80px;
    -webkit-transform: translateX(-50%) translateY(-10.5em) scale(0.01);
    -moz-transform: translateX(-50%) translateY(-10.5em) scale(0.01);
    -ms-transform: translateX(-50%) translateY(-10.5em) scale(0.01);
    -o-transform: translateX(-50%) translateY(-10.5em) scale(0.01);
    transform: translateX(-50%) translateY(-10.5em) scale(0.01);
  }
  .cd-vertical-nav li:nth-of-type(6) a::before {
    -webkit-transform: translateX(-50%) translateY(-10.5em) scale(0.35);
    -moz-transform: translateX(-50%) translateY(-10.5em) scale(0.35);
    -ms-transform: translateX(-50%) translateY(-10.5em) scale(0.35);
    -o-transform: translateX(-50%) translateY(-10.5em) scale(0.35);
    transform: translateX(-50%) translateY(-10.5em) scale(0.35);
  }
  .cd-vertical-nav .label {
    display: block;
    /* Force Hardware Acceleration */
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: translateZ(0);
    -moz-transform: translateZ(0);
    -ms-transform: translateZ(0);
    -o-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-transform: translateX(100%);
    -moz-transform: translateX(100%);
    -ms-transform: translateX(100%);
    -o-transform: translateX(100%);
    transform: translateX(100%);
    -webkit-transition: -webkit-transform 0.4s;
    -moz-transition: -moz-transform 0.4s;
    transition: transform 0.4s;
  }
  .no-touch .cd-vertical-nav:hover a::before, .no-touch .cd-vertical-nav:hover a::after, .touch .cd-vertical-nav li:nth-of-type(n) a::before, .touch .cd-vertical-nav li:nth-of-type(n) a::after {
    -webkit-transform: translateX(-50%) scale(1);
    -moz-transform: translateX(-50%) scale(1);
    -ms-transform: translateX(-50%) scale(1);
    -o-transform: translateX(-50%) scale(1);
    transform: translateX(-50%) scale(1);
    /* set a rotation to fix bug on Firefox - http://gielberkers.com/how-to-fix-shaking-css-transitions-in-firefox/ */
    -moz-transform: translateX(-50%) scale(1) rotate(0.02deg);
  }
  .no-touch .cd-vertical-nav:hover a::after, .touch .cd-vertical-nav a::after {
    opacity: 1;
    -webkit-transition: -webkit-transform 0.4s, opacity 0s;
    -moz-transition: -moz-transform 0.4s, opacity 0s;
    transition: transform 0.4s, opacity 0s;
  }
  .no-touch .cd-vertical-nav:hover .label, .touch .cd-vertical-nav .label {
    -webkit-transform: translateX(0);
    -moz-transform: translateX(0);
    -ms-transform: translateX(0);
    -o-transform: translateX(0);
    transform: translateX(0);
  }
}

// .cd-section:nth-of-type(even) {
//   background-color: #70557e;
// }




.cd-scroll-down {
  position: absolute;
  left: 50%;
  right: auto;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  transform: translateX(-50%);
  bottom: 20px;
  width: 44px;
  height: 44px;
  background: url(../../assets/landing/img/icons/cd-arrow-bottom.svg) no-repeat center center;
}

/* --------------------------------

no-js

-------------------------------- */
.no-js .cd-nav-trigger {
  display: none;
}

.no-js .cd-vertical-nav {
  position: static;
  height: auto;
  width: 100%;
  max-width: none;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 0;
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  -o-transform: scale(1);
  transform: scale(1);
}
.no-js .cd-vertical-nav::before {
  display: none;
}
.no-js .cd-vertical-nav ul::after {
  clear: both;
  content: "";
  display: table;
}
.no-js .cd-vertical-nav li {
  display: inline-block;
  float: left;
}
.no-js .cd-vertical-nav a {
  padding: 0 1em;
  margin: 1.5em 1em;
  color: #eaf2e3;
}
.no-js .cd-vertical-nav a.active {
  color: #c0a672;
}
.no-js .cd-vertical-nav a::after, .no-js .cd-vertical-nav a::before {
  display: none;
}
.no-js .cd-vertical-nav .label {
  -webkit-transform: translateX(0);
  -moz-transform: translateX(0);
  -ms-transform: translateX(0);
  -o-transform: translateX(0);
  transform: translateX(0);
}


//contact form

.ut-contactpop {
    background-color: $color_1;
    color: $color_4;
    width: 290px;
    bottom: 0;
	right: 6.3em;
    border-radius: 6px 6px 0px 0px;
    z-index: 999;
	padding: 0.5em 1.2rem;
	i {
		position: absolute;
		top: 5px;
		right: 10px;
		font-weight: 500;
		font-size: 36px;
		padding: 0;
		cursor: pointer;
		/* line-height: 32px; */
		/* background: #444; */
		/* color: #fff; */
		/* border-radius: 63%; */
		height: 32px;
		width: 32px;
		vertical-align: middle;
		text-align: center;
		display: flex;
		justify-content: center;
	}
	input {
		font-family: $font-family_4;
		font-size: 0.875em;
		width: 100%;
		height: 42px;
		padding: 0px 15px 0px 15px;
		background:transparent;
		outline: none;
		color: $color_4;
		border: solid 1px $color_4;
		// border-bottom: none;
		margin-bottom: .5rem;
		transition: all 0.3s ease-in-out;
		-webkit-transition: all 0.3s ease-in-out;
		-moz-transition: all 0.3s ease-in-out;
		-ms-transition: all 0.3s ease-in-out;
		&::placeholder{
			color: $color_4;
		}
		&:focus{
			color: $color_4;
			background-color: transparent;
		}
	}
	textarea {
		font-size: 0.875em;
	}
	h3 {
		border-radius: 6px 6px 0px 0px;
		text-align: left;
		color: $color_1;
		text-shadow: none;
		font-size: 16px;
		font-weight: normal;
		// background-color:#F4643C;
		color: #ffffff;
		margin: 2px;
		cursor: pointer;
		span {
			font-style: oblique;
		}
	}
	p {
		text-align: justify;
		padding: 15px 0;
		font-size: 0.875em;
	}
}
.fixed {
	position: fixed;
}





.btn-submit {
    font-weight: 700;
    font-size: 14px;
    display: flex;
    padding: 10px 40px;
    border-radius: 4px;
    transition: 0.5s;
    background: transparent;
	box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    border: solid 1px #ffffff;
    color: #ffffff;
    width: max-content;
}
.img2 {
	transform: scaleX(1);
}

.bs-none {
	box-shadow: none;
}
.bg-gray {
    background-color: #f6f6f6;
}


.industries {
    // base
        .icon-box {
            text-align: center;
            // border: 1px solid #e2eefd;
            padding: 40px 20px;
            transition: all ease-in-out 0.3s;
            // background: #fff;
            // width: 100%;
            .icon {
                margin: 0 auto;
                width: 64px;
                height: 64px;
                background: #f1f6fe;
                border-radius: 4rem;
                border: 1px solid #deebfd;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-bottom: 20px;
                transition: ease-in-out 0.3s;
                i {
                    color: $color_3;
                    font-size: 28px;
                    transition: ease-in-out 0.3s;
                }

                img {
                    max-width: 60%;
                }
            }
            h4 {
                font-weight: 700;
                margin-bottom: 15px;
                font-size: 21px;
                color:$color_4;
                a {
                    color: $color_7;
                    transition: ease-in-out 0.3s;
                    text-transform: uppercase;
                }
            }
            p {
                line-height: 24px;
                font-size: 1em;
                margin-bottom: 0;
            }
            &:hover {
                border-color: $border-color_1;
                box-shadow: 0px 0 25px 0 rgba(16, 110, 234, 0.1);
                h4 {
                    a {
                        color: $color_4;
                    }
                }
                .icon {
                    i {
                        color: $color_2;
                    }
                    border-color: $border-color_2;
                }
            }
        }

.flip {
    position: relative;
    margin-bottom: 1rem;
    h2 {
	    color:#ffffff;
        text-transform: uppercase;
        text-shadow: 2px 2px #000000;
    }
    .img-steel {
        // background: url(../../assets/landing/img/steel.jpg) center center;
		background: url(../../assets/landing/img/steel-thumb.jpg) center center;
    }
    .img-automotive {
        background: url(../../assets/landing/img/automotive-thumb.jpg) center center;
    }
    .img-cement {
        background: url(../../assets/landing/img/cement-thumb.jpg) center center;
    }
    .img-port {
        background: url(../../assets/landing/img/port.jpg) center center;
    }
    .front,
    .back {
        display: block;
        transition-timing-function: cubic-bezier(.175, .885, .32, 1.275);
        transition-duration: 5s;
        transition-property: transform, opacity;
    }
    .front {
        transform: rotateY(0deg);
        width: 100%;
        height: 44vh;
        background-size: cover;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        &::after{
            content: "";
            background: rgba(0, 0, 0, 0.04);

        }
    }
    >.back {
        position: absolute;
        opacity: 0;
        top: 0px;
        right: 12px;
        left: 12px;
        // left: 0px;
        // width: 100%;
        height: 100%;
        transform: rotateY(-180deg);
    }
    &:hover {
        .front {
            transform: rotateY(180deg);
        }
        .back {
            opacity: 1;
            transform: rotateY(0deg);
            background-color: #002490;
            color: #ffffff;
        }
    }
    &.flip-vertical {
        .back {
            transform: rotateX(-180deg);
        }
        &:hover {
            .front {
                transform: rotateX(180deg);
            }
            .back {
                transform: rotateX(0deg);
            }
        }
    }
}

.text-shadow {
text-shadow: 1px 1px rgba(0, 0, 0, 0.04), 2px 2px rgba(0, 0, 0, 0.04), 3px 3px rgba(0, 0, 0, 0.04), 4px 4px rgba(0, 0, 0, 0.04), 0.125rem 0.125rem rgba(0, 0, 0, 0.04), 6px 6px rgba(0, 0, 0, 0.04), 7px 7px rgba(0, 0, 0, 0.04), 8px 8px rgba(0, 0, 0, 0.04), 9px 9px rgba(0, 0, 0, 0.04), 0.3125rem 0.3125rem rgba(0, 0, 0, 0.04), 11px 11px rgba(0, 0, 0, 0.04), 12px 12px rgba(0, 0, 0, 0.04), 13px 13px rgba(0, 0, 0, 0.04), 14px 14px rgba(0, 0, 0, 0.04), 0.625rem 0.625rem rgba(0, 0, 0, 0.04), 16px 16px rgba(0, 0, 0, 0.04), 17px 17px rgba(0, 0, 0, 0.04), 18px 18px rgba(0, 0, 0, 0.04), 19px 19px rgba(0, 0, 0, 0.04), 1.25rem 1.25rem rgba(0, 0, 0, 0.04);
}
}

.bg-gray {
    background-color: #f6f6f6;
}




/*--------------------------------------------------------------
# Why Us
--------------------------------------------------------------*/
.whyus {
	.icon-box {
		text-align: center;
		border: 1px solid #e2eefd;
		padding: 80px 20px;
		transition: all ease-in-out 0.3s;
		background: #fff;
		.icon {
			margin: 0 auto;
			width: 64px;
			height: 64px;
			background: #f1f6fe;
			border-radius: 4px;
			border: 1px solid #deebfd;
			display: flex;
			align-items: center;
			justify-content: center;
			margin-bottom: 20px;
			transition: ease-in-out 0.3s;
			i {
				color: $color_1;
				font-size: 28px;
				transition: ease-in-out 0.3s;
			}
		}
		h4 {
			font-weight: 700;
			margin-bottom: 15px;
			font-size: 18px;
			a {
				color: $color_1;
				transition: ease-in-out 0.3s;
			}
		}
		p {
			font-size: 0.75em;
			color: $color_1;
			margin-bottom: 0.875rem;
		}
		&:hover {
			border-color: $border-color_1;
			box-shadow: 0px 0 25px 0 rgba(16, 110, 234, 0.1);
			h4 {
				a {
					color: $color_3;
				}
			}
			.icon {
				i {
					color: $color_3;
				}
				border-color: $border-color_2;
			}
		}
	}
}


// $color_1: var(--color-secondary);
// $color_2: #29486a;
// $font-family_1: var(--font-secondary);
// $background-color_1: #f7f9fc;
// $background-color_2: var(--color-secondary);
// $background-color_3: var(--color-primary);

/*--------------------------------------------------------------
# Why Choose Us Section
--------------------------------------------------------------*/
.why-us {
	// padding-bottom: 0;
	.img-bg {
		min-height: 580px;
		background-size: cover;
	}
	.slides {
		background-color: #ffffff;
	}
	h3 {
		font-size: 24px;
		font-weight: 700;
		color: $color_1;
	}
	h4 {
		font-size: 18px;
		font-weight: 400;
		color: $color_2;
		font-family: $font-family_1;
	}
	.swiper {
		margin: 80px 140px 80px 140px;
		overflow: hidden;
	}
	.swiper-button-prev {
		&:after {
			font-size: 24px;
			color: $color_1;
		}
		left: 80px;
	}
	.swiper-button-next {
		&:after {
			font-size: 24px;
			color: $color_1;
		}
		right: 80px;
	}
	.swiper-pagination {
		margin-top: 30px;
		position: relative;
		.swiper-pagination-bullet {
			background-color: $background-color_2;
		}
		.swiper-pagination-bullet-active {
			background-color: $background-color_3;
		}
	}
}
.page-about {
	.why-us {
		padding: 0 0 80px 0;
	}
}
@media (max-width: 1200px) {
	.why-us {
		.swiper {
			margin: 60px 60px 40px 60px;
		}
		.swiper-button-prev {
			display: none;
		}
		.swiper-button-next {
			display: none;
		}
	}
}
@media (max-width: 575px) {
	.why-us {
		.swiper {
			margin: 40px 40px 20px 40px;
		}
	}
}



  .section-header {
	text-align: center;
	padding-bottom: 15px;
  }

  .section-header h1 {
	font-size: 32px;
	font-weight: 600;
	margin-bottom: 10px;
	padding-bottom: 10px;
	position: relative;
  }

//   .section-header h2:after {
// 	content: "";
// 	position: absolute;
// 	display: block;
// 	width: 80px;
// 	height: 2px;
// 	background: $color_3;
// 	left: 0;
// 	right: 0;
// 	bottom: 0;
// 	margin: auto;
//   }

  .section-header p {
	margin-bottom: 0;
	padding: 0 10rem;
  }


/*--------------------------------------------------------------
# Cta
--------------------------------------------------------------*/
.cta {
	background: linear-gradient(rgba(40, 58, 90, 0.9), rgba(40, 58, 90, 0.9)), url("https://bootstrapmade.com/demo/templates/Arsha/assets/img/cta-bg.jpg") fixed center center;
	background-size: cover;
	padding: 120px 0;
	margin-top: 5rem;
	h3 {
		color: $color_4;
		font-size: 28px;
		font-weight: 700;
	}
	p {
		color: $color_4;
	}
	.cta-btn {
		font-family: $font-family_1;
		font-weight: 500;
		font-size: 16px;
		letter-spacing: 1px;
		display: inline-block;
		padding: 12px 40px;
		border-radius: 50px;
		transition: 0.5s;
		margin: 10px;
		border: 2px solid #fff;
		color: $color_4;
		&:hover {
			background: #47b2e4;
			border: 2px solid #47b2e4;
		}
	}
}
@media (max-width: 1024px) {
	.cta {
		background-attachment: scroll;
	}
}
@media (min-width: 769px) {
	.cta {
		.cta-btn-container {
			display: flex;
			align-items: center;
			justify-content: flex-end;
		}
	}
}


.insights {
	.content {
		padding: 60px 100px 0 100px;
		h3 {
			font-weight: 400;
			font-size: 34px;
			color: $color_1;
		}
		h4 {
			font-size: 20px;
			font-weight: 700;
			margin-top: 5px;
		}
		p {
			font-size: 15px;
			color: $color_2;
		}
	}
	.img {
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center center;
	}
	.accordion-list {
		padding: 0 50px 0 50px;
		ul {
			padding: 0;
			margin-bottom: 0;
			list-style: none;
		}
		li+li {
			margin-top: 15px;
		}
		li {
			padding: 20px;
			background: #fff;
			border-radius: 4px;
			.inline-item {
				margin-top: 2rem;
				li {
					display: inline-block;
					padding: 0.15rem 1rem 0.25rem 0.5rem;
					border: solid 1px #0d6efd;
					border-radius: 3rem;
					margin-right: 0.25rem;
					margin-bottom: 0.5rem;
					font-size: .75rem;
					background: #ffffff;
					color: #0d6efd;
				}
			}
		}
		a {
			display: block;
			position: relative;
			font-family: $font-family_3;
			font-size: 16px;
			line-height: 24px;
			font-weight: 600;
			padding-right: 30px;
			outline: none;
			cursor: pointer;
			color: #0d6efd;
			span{
				color: #0d6efd;
			}
		}
		span {
			color: $color_3;
			font-weight: 600;
			font-size: 18px;
			padding-right: 10px;
		}
		i {
			font-size: 24px;
			position: absolute;
			right: 0;
			top: 0;
		}
		p {
			margin-bottom: 0;
			padding: 10px 0 0 0;
		}
		.icon-show {
			display: none;
		}
		a.collapsed {
			color: $color_1;
			&:hover {
				color: $color_3;
			}
			.icon-show {
				display: inline-block;
			}
			.icon-close {
				display: none;
			}
		}
		.list-unstyled {
			li {
				padding: 0 15px 0;
				margin: 0;
				i {
					position: relative;
					font-size: 18px;
					line-height: normal;
					top: 2px;
				}
			}
		}
	}
}
@media (max-width: 1024px) {
	.insights {
		.content {
			padding-left: 0;
			padding-right: 0;
		}
		.accordion-list {
			padding-left: 0;
			padding-right: 0;
		}
	}
}
@media (max-width: 992px) {
	.insights {
		.img {
			min-height: 400px;
		}
		.content {
			padding-top: 30px;
		}
		.accordion-list {
			padding-bottom: 30px;
		}
	}
}
@media (max-width: 575px) {
	.insights {
		.img {
			min-height: 200px;
		}
	}

}

@media only screen and  (max-width: 575px) {
	#intro .img1 {
		display: none;
	}
	.section-header p {
		margin-bottom: 0;
		padding: 0 .25rem;
	  }
	  .whyUsCarousel  {
		  padding:1rem;
	  }
	  .ut-contactpop {
		width: 100%;
		right: auto;
	  }
}

.banner {
    width: 100%;
    height: 58vh;
    background-size: cover!important;
    position: relative!important;
}
.overlay {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 0;
    left: 0;
  }

  .steel-img {
	background: url(../../assets/landing/img/71.png) center center!important;
  }
  .automotive-img {
	background: url(../../assets/landing/img/75.png) center center!important;
  }
  .cement-img {
	background: url(../../assets/landing/img/76.png) center center!important;
  }
  .port-img {
	background: url(../../assets/landing/img/83.png) center center!important;
  }

  .feature {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 4rem;
    width: 4rem;
    font-size: 2rem;
}
.section-header h2{
	font-size: 2rem;
}
a {
	text-decoration: none;
}


section h2, .h2 {
	font-size: 2rem!important;
	font-family: 'Roboto', Courier, monospace!important;
    letter-spacing: normal;
    margin: 0 0 16px;
}
