
$color_1: #202020;
$color_2: #00FFFF;
$color_3: #1d46f5;
$color_4: #ffffff;
$color_5: rgba(255, 255, 255, 0.7);
$color_6: #ff8260;
$color_7: #222222;
$color_8: #555555;
$color_9: #8b8b8b;
$color_10: #111;
$color_11: #011F79;
$color_12: #ddd;
$color_13: rgba(255, 255, 255, 0.4);
$color_14: #eee;
$color_15: #3c3c3c;
$color_16: #6ba7f5;
$color_17: #aaaaaa;
$color_18: #777777;
$color_19: #bababa;
$color_20: #ccc;
$color_21: #0d58ba;
$color_22: #FECA45;
$font-family_1: "Open Sans", sans-serif;
$font-family_2: "Roboto", sans-serif;
$font-family_3: "Poppins", sans-serif;
$font-family_4: 'Lato', sans-serif;;
$background-color_1: #00FFFF;
$background-color_2: #106eea;
$background-color_3: #00FFFF;
$background-color_4: #f1f6fe;
$background-color_5: rgba(255, 255, 255, 0.5);
$background-color_4: #FFB764;
$background-color_5: #FF646D;
$border-color_1: #fff;
$border-color_2: #106eea;
$border-color_5: #d1252e;
$border-top-color_1: #e2eefd;
$border-top-color_2: #eee;
@mixin svg-icon($path, $height, $width, $color ) {
    height: $height;
    width: $width;
    background-color: $color;
    mask-image: url($path);
    background-size: cover;
    -webkit-mask-size: contain;
    mask-size: contain;
    -webkit-mask-position: center;
    mask-position: center;
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
  }
